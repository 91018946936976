import { useEffect } from "react";

import { Sankey } from "@ant-design/plots";
import { ChartTitle, ChartWrapper } from "./Charts.styled";

import { useDispatch, useSelector } from "react-redux";
import { setCode } from "../../store/code/code.reducer";
import { selectCode } from "../../store/code/code.selectors";

import sankeyFP from "../../dataset/sankeyFP";

export const CodeSankeyChart = () => {
  const dispatch = useDispatch();

  useEffect(() => {
    dispatch(setCode(sankeyFP));
  }, [dispatch]);

  const graphData = useSelector(selectCode);

  const config = {
    data: graphData,
    sourceField: "source",
    targetField: "target",
    weightField: "value",
    nodeWidthRatio: 0.01,
    nodePaddingRatio: 0.03,
    height: 1000,
    nodeDraggable: true,
    rawFields: ["path"],
    tooltip: {
      fields: ["path", "value"],
      formatter: ({ path, value }: any) => {
        return {
          name: path,
          value: value,
        };
      },
    },
  };
  return (
    <ChartWrapper>
      <ChartTitle>Языки программирования</ChartTitle>
      <Sankey {...config} />
    </ChartWrapper>
  );
};
