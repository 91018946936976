import { createSlice, PayloadAction } from "@reduxjs/toolkit";

export interface IThread {
  path: string;
  source: string;
  target: string;
  value: number;
}

interface ICode {
  code: IThread[];
}

const initialState: ICode = {
  code: [],
};

const codeSlice = createSlice({
  name: "code",
  initialState,
  reducers: {
    setCode(state, action: PayloadAction<IThread[]>) {
      return { ...state, code: action.payload };
    },
  },
});

const { actions, reducer } = codeSlice;

export const { setCode } = actions;

export default reducer;
