import React from "react";
import { DashboardWrapper } from "./Dashboard.styled";

import { CodeSankeyChart } from "components/Charts/CodeSankeyChart";

const Dashboard: React.FC = () => {
  return (
    <DashboardWrapper>
      <CodeSankeyChart />
    </DashboardWrapper>
  );
};

export default Dashboard;
