import React from "react";
import { useSelector } from "react-redux";
import Spin from "antd/lib/spin";
import Layout from "antd/lib/layout";

import { selectLoading } from "./store/app/app.selectors";

import Header from "shared/components/Header/Header";
import Dashboard from "./components/Dashboard/Dashboard";

function App() {
  const { loading, label } = useSelector(selectLoading);

  return (
    <Spin tip={label} spinning={loading}>
      <Layout>
        <Header />
        <Dashboard />
      </Layout>
    </Spin>
  );
}

export default React.memo(App);
