import { IThread } from "../store/code/code.reducer";

export const rawFPData = {
  node: {
    thickness: 20,
    line: { color: "black", width: 0.5 },
    label: [
      "Assembly",
      "C",
      "C++",
      "C#",
      "Erlang",
      "Go",
      "GraphQL",
      "Haskell",
      "Java",
      "JavaScript/TypeScript",
      "Kotlin",
      "Objective-C",
      "Pascal",
      "Perl",
      "PHP",
      "Python",
      "R",
      "Ruby",
      "SQL",
      "Swift",
      "Visual Basic",
      "1\u0421",
      "\u0410\u043d\u0430\u043b\u0438\u0442\u0438\u043a IT",
      "Data analyst",
      "Data engineer",
      "Data scientist",
      "DevOps \u0438\u043d\u0436\u0435\u043d\u0435\u0440",
      "\u0420\u0430\u0437\u0440\u0430\u0431\u043e\u0442\u0447\u0438\u043a/\u043f\u0440\u043e\u0433\u0440\u0430\u043c\u043c\u0438\u0441\u0442 1\u0421",
      "\u0420\u0430\u0437\u0440\u0430\u0431\u043e\u0442\u0447\u0438\u043a/\u043f\u0440\u043e\u0433\u0440\u0430\u043c\u043c\u0438\u0441\u0442 backend",
      "\u0420\u0430\u0437\u0440\u0430\u0431\u043e\u0442\u0447\u0438\u043a/\u043f\u0440\u043e\u0433\u0440\u0430\u043c\u043c\u0438\u0441\u0442 frontend",
      "\u0420\u0430\u0437\u0440\u0430\u0431\u043e\u0442\u0447\u0438\u043a/\u043f\u0440\u043e\u0433\u0440\u0430\u043c\u043c\u0438\u0441\u0442 web",
      "\u0420\u0430\u0437\u0440\u0430\u0431\u043e\u0442\u0447\u0438\u043a/\u043f\u0440\u043e\u0433\u0440\u0430\u043c\u043c\u0438\u0441\u0442 \u0431\u0430\u0437 \u0434\u0430\u043d\u043d\u044b\u0445",
      "\u0420\u0430\u0437\u0440\u0430\u0431\u043e\u0442\u0447\u0438\u043a/\u043f\u0440\u043e\u0433\u0440\u0430\u043c\u043c\u0438\u0441\u0442 \u043c\u043e\u0431\u0438\u043b\u044c\u043d\u044b\u0445 \u043f\u0440\u0438\u043b\u043e\u0436\u0435\u043d\u0438\u0439",
      "\u0421\u0438\u0441\u0442\u0435\u043c\u043d\u044b\u0439 \u043f\u0440\u043e\u0433\u0440\u0430\u043c\u043c\u0438\u0441\u0442",
      "\u0422\u0435\u0441\u0442\u0438\u0440\u043e\u0432\u0449\u0438\u043a",
    ],
    customdata: [
      "\u0422\u043e\u043f 5 \u0424\u041f:<br>DevOps \u0438\u043d\u0436\u0435\u043d\u0435\u0440 \u2014 78 \u0432\u0430\u043a.<br>\u0421\u0438\u0441\u0442\u0435\u043c\u043d\u044b\u0439 \u043f\u0440\u043e\u0433\u0440\u0430\u043c\u043c\u0438\u0441\u0442 \u2014 51 \u0432\u0430\u043a.<br>\u0410\u043d\u0430\u043b\u0438\u0442\u0438\u043a IT \u2014 39 \u0432\u0430\u043a.<br>\u0422\u0435\u0441\u0442\u0438\u0440\u043e\u0432\u0449\u0438\u043a \u2014 32 \u0432\u0430\u043a.<br>\u0420\u0430\u0437\u0440\u0430\u0431\u043e\u0442\u0447\u0438\u043a/\u043f\u0440\u043e\u0433\u0440\u0430\u043c\u043c\u0438\u0441\u0442 frontend \u2014 31 \u0432\u0430\u043a.",
      "\u0422\u043e\u043f 5 \u0424\u041f:<br>\u0420\u0430\u0437\u0440\u0430\u0431\u043e\u0442\u0447\u0438\u043a/\u043f\u0440\u043e\u0433\u0440\u0430\u043c\u043c\u0438\u0441\u0442 backend \u2014 178 \u0432\u0430\u043a.<br>\u0422\u0435\u0441\u0442\u0438\u0440\u043e\u0432\u0449\u0438\u043a \u2014 153 \u0432\u0430\u043a.<br>DevOps \u0438\u043d\u0436\u0435\u043d\u0435\u0440 \u2014 141 \u0432\u0430\u043a.<br>\u0421\u0438\u0441\u0442\u0435\u043c\u043d\u044b\u0439 \u043f\u0440\u043e\u0433\u0440\u0430\u043c\u043c\u0438\u0441\u0442 \u2014 122 \u0432\u0430\u043a.<br>\u0410\u043d\u0430\u043b\u0438\u0442\u0438\u043a IT \u2014 98 \u0432\u0430\u043a.",
      "\u0422\u043e\u043f 5 \u0424\u041f:<br>\u0422\u0435\u0441\u0442\u0438\u0440\u043e\u0432\u0449\u0438\u043a \u2014 1056 \u0432\u0430\u043a.<br>\u0420\u0430\u0437\u0440\u0430\u0431\u043e\u0442\u0447\u0438\u043a/\u043f\u0440\u043e\u0433\u0440\u0430\u043c\u043c\u0438\u0441\u0442 backend \u2014 989 \u0432\u0430\u043a.<br>DevOps \u0438\u043d\u0436\u0435\u043d\u0435\u0440 \u2014 771 \u0432\u0430\u043a.<br>\u0410\u043d\u0430\u043b\u0438\u0442\u0438\u043a IT \u2014 577 \u0432\u0430\u043a.<br>\u0420\u0430\u0437\u0440\u0430\u0431\u043e\u0442\u0447\u0438\u043a/\u043f\u0440\u043e\u0433\u0440\u0430\u043c\u043c\u0438\u0441\u0442 frontend \u2014 419 \u0432\u0430\u043a.",
      "\u0422\u043e\u043f 5 \u0424\u041f:<br>\u0422\u0435\u0441\u0442\u0438\u0440\u043e\u0432\u0449\u0438\u043a \u2014 3449 \u0432\u0430\u043a.<br>\u0420\u0430\u0437\u0440\u0430\u0431\u043e\u0442\u0447\u0438\u043a/\u043f\u0440\u043e\u0433\u0440\u0430\u043c\u043c\u0438\u0441\u0442 backend \u2014 2435 \u0432\u0430\u043a.<br>\u0420\u0430\u0437\u0440\u0430\u0431\u043e\u0442\u0447\u0438\u043a/\u043f\u0440\u043e\u0433\u0440\u0430\u043c\u043c\u0438\u0441\u0442 web \u2014 1304 \u0432\u0430\u043a.<br>\u0420\u0430\u0437\u0440\u0430\u0431\u043e\u0442\u0447\u0438\u043a/\u043f\u0440\u043e\u0433\u0440\u0430\u043c\u043c\u0438\u0441\u0442 frontend \u2014 1186 \u0432\u0430\u043a.<br>\u0410\u043d\u0430\u043b\u0438\u0442\u0438\u043a IT \u2014 889 \u0432\u0430\u043a.",
      "\u0422\u043e\u043f 5 \u0424\u041f:<br>\u0420\u0430\u0437\u0440\u0430\u0431\u043e\u0442\u0447\u0438\u043a/\u043f\u0440\u043e\u0433\u0440\u0430\u043c\u043c\u0438\u0441\u0442 backend \u2014 66 \u0432\u0430\u043a.<br>\u0422\u0435\u0441\u0442\u0438\u0440\u043e\u0432\u0449\u0438\u043a \u2014 42 \u0432\u0430\u043a.<br>DevOps \u0438\u043d\u0436\u0435\u043d\u0435\u0440 \u2014 29 \u0432\u0430\u043a.<br>\u0410\u043d\u0430\u043b\u0438\u0442\u0438\u043a IT \u2014 20 \u0432\u0430\u043a.<br>\u0420\u0430\u0437\u0440\u0430\u0431\u043e\u0442\u0447\u0438\u043a/\u043f\u0440\u043e\u0433\u0440\u0430\u043c\u043c\u0438\u0441\u0442 frontend \u2014 8 \u0432\u0430\u043a.",
      "\u0422\u043e\u043f 5 \u0424\u041f:<br>DevOps \u0438\u043d\u0436\u0435\u043d\u0435\u0440 \u2014 3076 \u0432\u0430\u043a.<br>\u0420\u0430\u0437\u0440\u0430\u0431\u043e\u0442\u0447\u0438\u043a/\u043f\u0440\u043e\u0433\u0440\u0430\u043c\u043c\u0438\u0441\u0442 backend \u2014 3010 \u0432\u0430\u043a.<br>\u0422\u0435\u0441\u0442\u0438\u0440\u043e\u0432\u0449\u0438\u043a \u2014 1243 \u0432\u0430\u043a.<br>\u0420\u0430\u0437\u0440\u0430\u0431\u043e\u0442\u0447\u0438\u043a/\u043f\u0440\u043e\u0433\u0440\u0430\u043c\u043c\u0438\u0441\u0442 frontend \u2014 1099 \u0432\u0430\u043a.<br>\u0420\u0430\u0437\u0440\u0430\u0431\u043e\u0442\u0447\u0438\u043a/\u043f\u0440\u043e\u0433\u0440\u0430\u043c\u043c\u0438\u0441\u0442 web \u2014 463 \u0432\u0430\u043a.",
      "\u0422\u043e\u043f 5 \u0424\u041f:<br>\u0420\u0430\u0437\u0440\u0430\u0431\u043e\u0442\u0447\u0438\u043a/\u043f\u0440\u043e\u0433\u0440\u0430\u043c\u043c\u0438\u0441\u0442 frontend \u2014 2473 \u0432\u0430\u043a.<br>\u0420\u0430\u0437\u0440\u0430\u0431\u043e\u0442\u0447\u0438\u043a/\u043f\u0440\u043e\u0433\u0440\u0430\u043c\u043c\u0438\u0441\u0442 backend \u2014 882 \u0432\u0430\u043a.<br>\u0422\u0435\u0441\u0442\u0438\u0440\u043e\u0432\u0449\u0438\u043a \u2014 323 \u0432\u0430\u043a.<br>\u0420\u0430\u0437\u0440\u0430\u0431\u043e\u0442\u0447\u0438\u043a/\u043f\u0440\u043e\u0433\u0440\u0430\u043c\u043c\u0438\u0441\u0442 web \u2014 225 \u0432\u0430\u043a.<br>\u0410\u043d\u0430\u043b\u0438\u0442\u0438\u043a IT \u2014 131 \u0432\u0430\u043a.",
      "\u0422\u043e\u043f 5 \u0424\u041f:<br>\u0410\u043d\u0430\u043b\u0438\u0442\u0438\u043a IT \u2014 17 \u0432\u0430\u043a.<br>\u0420\u0430\u0437\u0440\u0430\u0431\u043e\u0442\u0447\u0438\u043a/\u043f\u0440\u043e\u0433\u0440\u0430\u043c\u043c\u0438\u0441\u0442 backend \u2014 16 \u0432\u0430\u043a.<br>DevOps \u0438\u043d\u0436\u0435\u043d\u0435\u0440 \u2014 10 \u0432\u0430\u043a.<br>\u0420\u0430\u0437\u0440\u0430\u0431\u043e\u0442\u0447\u0438\u043a/\u043f\u0440\u043e\u0433\u0440\u0430\u043c\u043c\u0438\u0441\u0442 frontend \u2014 4 \u0432\u0430\u043a.<br>Data scientist \u2014 3 \u0432\u0430\u043a.",
      "\u0422\u043e\u043f 5 \u0424\u041f:<br>\u0422\u0435\u0441\u0442\u0438\u0440\u043e\u0432\u0449\u0438\u043a \u2014 11171 \u0432\u0430\u043a.<br>\u0420\u0430\u0437\u0440\u0430\u0431\u043e\u0442\u0447\u0438\u043a/\u043f\u0440\u043e\u0433\u0440\u0430\u043c\u043c\u0438\u0441\u0442 backend \u2014 4186 \u0432\u0430\u043a.<br>DevOps \u0438\u043d\u0436\u0435\u043d\u0435\u0440 \u2014 3909 \u0432\u0430\u043a.<br>\u0410\u043d\u0430\u043b\u0438\u0442\u0438\u043a IT \u2014 2753 \u0432\u0430\u043a.<br>\u0420\u0430\u0437\u0440\u0430\u0431\u043e\u0442\u0447\u0438\u043a/\u043f\u0440\u043e\u0433\u0440\u0430\u043c\u043c\u0438\u0441\u0442 frontend \u2014 2454 \u0432\u0430\u043a.",
      "\u0422\u043e\u043f 5 \u0424\u041f:<br>\u0420\u0430\u0437\u0440\u0430\u0431\u043e\u0442\u0447\u0438\u043a/\u043f\u0440\u043e\u0433\u0440\u0430\u043c\u043c\u0438\u0441\u0442 frontend \u2014 33335 \u0432\u0430\u043a.<br>\u0420\u0430\u0437\u0440\u0430\u0431\u043e\u0442\u0447\u0438\u043a/\u043f\u0440\u043e\u0433\u0440\u0430\u043c\u043c\u0438\u0441\u0442 web \u2014 13475 \u0432\u0430\u043a.<br>\u0420\u0430\u0437\u0440\u0430\u0431\u043e\u0442\u0447\u0438\u043a/\u043f\u0440\u043e\u0433\u0440\u0430\u043c\u043c\u0438\u0441\u0442 backend \u2014 7385 \u0432\u0430\u043a.<br>\u0422\u0435\u0441\u0442\u0438\u0440\u043e\u0432\u0449\u0438\u043a \u2014 6640 \u0432\u0430\u043a.<br>\u0410\u043d\u0430\u043b\u0438\u0442\u0438\u043a IT \u2014 2266 \u0432\u0430\u043a.",
      "\u0422\u043e\u043f 5 \u0424\u041f:<br>\u0422\u0435\u0441\u0442\u0438\u0440\u043e\u0432\u0449\u0438\u043a \u2014 1952 \u0432\u0430\u043a.<br>\u0420\u0430\u0437\u0440\u0430\u0431\u043e\u0442\u0447\u0438\u043a/\u043f\u0440\u043e\u0433\u0440\u0430\u043c\u043c\u0438\u0441\u0442 backend \u2014 1015 \u0432\u0430\u043a.<br>\u0420\u0430\u0437\u0440\u0430\u0431\u043e\u0442\u0447\u0438\u043a/\u043f\u0440\u043e\u0433\u0440\u0430\u043c\u043c\u0438\u0441\u0442 \u043c\u043e\u0431\u0438\u043b\u044c\u043d\u044b\u0445 \u043f\u0440\u0438\u043b\u043e\u0436\u0435\u043d\u0438\u0439 \u2014 967 \u0432\u0430\u043a.<br>\u0420\u0430\u0437\u0440\u0430\u0431\u043e\u0442\u0447\u0438\u043a/\u043f\u0440\u043e\u0433\u0440\u0430\u043c\u043c\u0438\u0441\u0442 frontend \u2014 410 \u0432\u0430\u043a.<br>DevOps \u0438\u043d\u0436\u0435\u043d\u0435\u0440 \u2014 391 \u0432\u0430\u043a.",
      "\u0422\u043e\u043f 5 \u0424\u041f:<br>\u0420\u0430\u0437\u0440\u0430\u0431\u043e\u0442\u0447\u0438\u043a/\u043f\u0440\u043e\u0433\u0440\u0430\u043c\u043c\u0438\u0441\u0442 \u043c\u043e\u0431\u0438\u043b\u044c\u043d\u044b\u0445 \u043f\u0440\u0438\u043b\u043e\u0436\u0435\u043d\u0438\u0439 \u2014 537 \u0432\u0430\u043a.<br>\u0422\u0435\u0441\u0442\u0438\u0440\u043e\u0432\u0449\u0438\u043a \u2014 118 \u0432\u0430\u043a.<br>\u0420\u0430\u0437\u0440\u0430\u0431\u043e\u0442\u0447\u0438\u043a/\u043f\u0440\u043e\u0433\u0440\u0430\u043c\u043c\u0438\u0441\u0442 frontend \u2014 87 \u0432\u0430\u043a.<br>\u0410\u043d\u0430\u043b\u0438\u0442\u0438\u043a IT \u2014 22 \u0432\u0430\u043a.<br>DevOps \u0438\u043d\u0436\u0435\u043d\u0435\u0440 \u2014 18 \u0432\u0430\u043a.",
      "\u0422\u043e\u043f 5 \u0424\u041f:<br>\u0420\u0430\u0437\u0440\u0430\u0431\u043e\u0442\u0447\u0438\u043a/\u043f\u0440\u043e\u0433\u0440\u0430\u043c\u043c\u0438\u0441\u0442 1\u0421 \u2014 22 \u0432\u0430\u043a.<br>\u0410\u043d\u0430\u043b\u0438\u0442\u0438\u043a IT \u2014 12 \u0432\u0430\u043a.<br>\u0420\u0430\u0437\u0440\u0430\u0431\u043e\u0442\u0447\u0438\u043a/\u043f\u0440\u043e\u0433\u0440\u0430\u043c\u043c\u0438\u0441\u0442 web \u2014 11 \u0432\u0430\u043a.<br>\u0420\u0430\u0437\u0440\u0430\u0431\u043e\u0442\u0447\u0438\u043a/\u043f\u0440\u043e\u0433\u0440\u0430\u043c\u043c\u0438\u0441\u0442 frontend \u2014 8 \u0432\u0430\u043a.<br>DevOps \u0438\u043d\u0436\u0435\u043d\u0435\u0440 \u2014 5 \u0432\u0430\u043a.",
      "\u0422\u043e\u043f 5 \u0424\u041f:<br>DevOps \u0438\u043d\u0436\u0435\u043d\u0435\u0440 \u2014 845 \u0432\u0430\u043a.<br>\u0422\u0435\u0441\u0442\u0438\u0440\u043e\u0432\u0449\u0438\u043a \u2014 208 \u0432\u0430\u043a.<br>\u0410\u043d\u0430\u043b\u0438\u0442\u0438\u043a IT \u2014 125 \u0432\u0430\u043a.<br>\u0420\u0430\u0437\u0440\u0430\u0431\u043e\u0442\u0447\u0438\u043a/\u043f\u0440\u043e\u0433\u0440\u0430\u043c\u043c\u0438\u0441\u0442 backend \u2014 83 \u0432\u0430\u043a.<br>\u0420\u0430\u0437\u0440\u0430\u0431\u043e\u0442\u0447\u0438\u043a/\u043f\u0440\u043e\u0433\u0440\u0430\u043c\u043c\u0438\u0441\u0442 web \u2014 82 \u0432\u0430\u043a.",
      "\u0422\u043e\u043f 5 \u0424\u041f:<br>\u0420\u0430\u0437\u0440\u0430\u0431\u043e\u0442\u0447\u0438\u043a/\u043f\u0440\u043e\u0433\u0440\u0430\u043c\u043c\u0438\u0441\u0442 web \u2014 11079 \u0432\u0430\u043a.<br>\u0420\u0430\u0437\u0440\u0430\u0431\u043e\u0442\u0447\u0438\u043a/\u043f\u0440\u043e\u0433\u0440\u0430\u043c\u043c\u0438\u0441\u0442 backend \u2014 6511 \u0432\u0430\u043a.<br>\u0420\u0430\u0437\u0440\u0430\u0431\u043e\u0442\u0447\u0438\u043a/\u043f\u0440\u043e\u0433\u0440\u0430\u043c\u043c\u0438\u0441\u0442 frontend \u2014 3661 \u0432\u0430\u043a.<br>\u0420\u0430\u0437\u0440\u0430\u0431\u043e\u0442\u0447\u0438\u043a/\u043f\u0440\u043e\u0433\u0440\u0430\u043c\u043c\u0438\u0441\u0442 1\u0421 \u2014 2252 \u0432\u0430\u043a.<br>DevOps \u0438\u043d\u0436\u0435\u043d\u0435\u0440 \u2014 2088 \u0432\u0430\u043a.",
      "\u0422\u043e\u043f 5 \u0424\u041f:<br>DevOps \u0438\u043d\u0436\u0435\u043d\u0435\u0440 \u2014 10551 \u0432\u0430\u043a.<br>\u0422\u0435\u0441\u0442\u0438\u0440\u043e\u0432\u0449\u0438\u043a \u2014 7906 \u0432\u0430\u043a.<br>\u0410\u043d\u0430\u043b\u0438\u0442\u0438\u043a IT \u2014 7768 \u0432\u0430\u043a.<br>Data scientist \u2014 4429 \u0432\u0430\u043a.<br>\u0420\u0430\u0437\u0440\u0430\u0431\u043e\u0442\u0447\u0438\u043a/\u043f\u0440\u043e\u0433\u0440\u0430\u043c\u043c\u0438\u0441\u0442 backend \u2014 4251 \u0432\u0430\u043a.",
      "\u0422\u043e\u043f 5 \u0424\u041f:<br>\u0410\u043d\u0430\u043b\u0438\u0442\u0438\u043a IT \u2014 757 \u0432\u0430\u043a.<br>Data scientist \u2014 391 \u0432\u0430\u043a.<br>Data analyst \u2014 234 \u0432\u0430\u043a.<br>Data engineer \u2014 81 \u0432\u0430\u043a.<br>\u0420\u0430\u0437\u0440\u0430\u0431\u043e\u0442\u0447\u0438\u043a/\u043f\u0440\u043e\u0433\u0440\u0430\u043c\u043c\u0438\u0441\u0442 backend \u2014 81 \u0432\u0430\u043a.",
      "\u0422\u043e\u043f 5 \u0424\u041f:<br>DevOps \u0438\u043d\u0436\u0435\u043d\u0435\u0440 \u2014 1155 \u0432\u0430\u043a.<br>\u0420\u0430\u0437\u0440\u0430\u0431\u043e\u0442\u0447\u0438\u043a/\u043f\u0440\u043e\u0433\u0440\u0430\u043c\u043c\u0438\u0441\u0442 backend \u2014 714 \u0432\u0430\u043a.<br>\u0422\u0435\u0441\u0442\u0438\u0440\u043e\u0432\u0449\u0438\u043a \u2014 584 \u0432\u0430\u043a.<br>\u0420\u0430\u0437\u0440\u0430\u0431\u043e\u0442\u0447\u0438\u043a/\u043f\u0440\u043e\u0433\u0440\u0430\u043c\u043c\u0438\u0441\u0442 frontend \u2014 488 \u0432\u0430\u043a.<br>\u0420\u0430\u0437\u0440\u0430\u0431\u043e\u0442\u0447\u0438\u043a/\u043f\u0440\u043e\u0433\u0440\u0430\u043c\u043c\u0438\u0441\u0442 web \u2014 345 \u0432\u0430\u043a.",
      "\u0422\u043e\u043f 5 \u0424\u041f:<br>\u0410\u043d\u0430\u043b\u0438\u0442\u0438\u043a IT \u2014 27310 \u0432\u0430\u043a.<br>\u0422\u0435\u0441\u0442\u0438\u0440\u043e\u0432\u0449\u0438\u043a \u2014 18742 \u0432\u0430\u043a.<br>\u0420\u0430\u0437\u0440\u0430\u0431\u043e\u0442\u0447\u0438\u043a/\u043f\u0440\u043e\u0433\u0440\u0430\u043c\u043c\u0438\u0441\u0442 backend \u2014 7204 \u0432\u0430\u043a.<br>\u0420\u0430\u0437\u0440\u0430\u0431\u043e\u0442\u0447\u0438\u043a/\u043f\u0440\u043e\u0433\u0440\u0430\u043c\u043c\u0438\u0441\u0442 1\u0421 \u2014 7080 \u0432\u0430\u043a.<br>\u0420\u0430\u0437\u0440\u0430\u0431\u043e\u0442\u0447\u0438\u043a/\u043f\u0440\u043e\u0433\u0440\u0430\u043c\u043c\u0438\u0441\u0442 web \u2014 4556 \u0432\u0430\u043a.",
      "\u0422\u043e\u043f 5 \u0424\u041f:<br>\u0420\u0430\u0437\u0440\u0430\u0431\u043e\u0442\u0447\u0438\u043a/\u043f\u0440\u043e\u0433\u0440\u0430\u043c\u043c\u0438\u0441\u0442 \u043c\u043e\u0431\u0438\u043b\u044c\u043d\u044b\u0445 \u043f\u0440\u0438\u043b\u043e\u0436\u0435\u043d\u0438\u0439 \u2014 994 \u0432\u0430\u043a.<br>\u0422\u0435\u0441\u0442\u0438\u0440\u043e\u0432\u0449\u0438\u043a \u2014 772 \u0432\u0430\u043a.<br>\u0420\u0430\u0437\u0440\u0430\u0431\u043e\u0442\u0447\u0438\u043a/\u043f\u0440\u043e\u0433\u0440\u0430\u043c\u043c\u0438\u0441\u0442 frontend \u2014 260 \u0432\u0430\u043a.<br>\u0410\u043d\u0430\u043b\u0438\u0442\u0438\u043a IT \u2014 220 \u0432\u0430\u043a.<br>DevOps \u0438\u043d\u0436\u0435\u043d\u0435\u0440 \u2014 151 \u0432\u0430\u043a.",
      "\u0422\u043e\u043f 5 \u0424\u041f:<br>\u0410\u043d\u0430\u043b\u0438\u0442\u0438\u043a IT \u2014 150 \u0432\u0430\u043a.<br>\u0422\u0435\u0441\u0442\u0438\u0440\u043e\u0432\u0449\u0438\u043a \u2014 33 \u0432\u0430\u043a.<br>\u0420\u0430\u0437\u0440\u0430\u0431\u043e\u0442\u0447\u0438\u043a/\u043f\u0440\u043e\u0433\u0440\u0430\u043c\u043c\u0438\u0441\u0442 1\u0421 \u2014 26 \u0432\u0430\u043a.<br>Data engineer \u2014 16 \u0432\u0430\u043a.<br>Data analyst \u2014 12 \u0432\u0430\u043a.",
      "\u0422\u043e\u043f 5 \u0424\u041f:<br>\u0420\u0430\u0437\u0440\u0430\u0431\u043e\u0442\u0447\u0438\u043a/\u043f\u0440\u043e\u0433\u0440\u0430\u043c\u043c\u0438\u0441\u0442 1\u0421 \u2014 45451 \u0432\u0430\u043a.",
      "\u0422\u043e\u043f 5 \u044f\u0437\u044b\u043a\u043e\u0432:<br>SQL \u2014 27310 \u0432\u0430\u043a.<br>Python \u2014 7768 \u0432\u0430\u043a.<br>Java \u2014 2753 \u0432\u0430\u043a.<br>JavaScript/TypeScript \u2014 2266 \u0432\u0430\u043a.<br>C# \u2014 889 \u0432\u0430\u043a.",
      "\u0422\u043e\u043f 5 \u044f\u0437\u044b\u043a\u043e\u0432:<br>SQL \u2014 4180 \u0432\u0430\u043a.<br>Python \u2014 3329 \u0432\u0430\u043a.<br>R \u2014 234 \u0432\u0430\u043a.<br>Java \u2014 164 \u0432\u0430\u043a.<br>JavaScript/TypeScript \u2014 164 \u0432\u0430\u043a.",
      "\u0422\u043e\u043f 5 \u044f\u0437\u044b\u043a\u043e\u0432:<br>SQL \u2014 3194 \u0432\u0430\u043a.<br>Python \u2014 3047 \u0432\u0430\u043a.<br>Java \u2014 1246 \u0432\u0430\u043a.<br>Go \u2014 239 \u0432\u0430\u043a.<br>JavaScript/TypeScript \u2014 187 \u0432\u0430\u043a.",
      "\u0422\u043e\u043f 5 \u044f\u0437\u044b\u043a\u043e\u0432:<br>Python \u2014 4429 \u0432\u0430\u043a.<br>SQL \u2014 2714 \u0432\u0430\u043a.<br>Java \u2014 420 \u0432\u0430\u043a.<br>R \u2014 391 \u0432\u0430\u043a.<br>C++ \u2014 363 \u0432\u0430\u043a.",
      "\u0422\u043e\u043f 5 \u044f\u0437\u044b\u043a\u043e\u0432:<br>Python \u2014 10551 \u0432\u0430\u043a.<br>Java \u2014 3909 \u0432\u0430\u043a.<br>SQL \u2014 3777 \u0432\u0430\u043a.<br>Go \u2014 3076 \u0432\u0430\u043a.<br>PHP \u2014 2088 \u0432\u0430\u043a.",
      "\u0422\u043e\u043f 5 \u044f\u0437\u044b\u043a\u043e\u0432:<br>1\u0421 \u2014 45451 \u0432\u0430\u043a.<br>SQL \u2014 7080 \u0432\u0430\u043a.<br>PHP \u2014 2252 \u0432\u0430\u043a.<br>JavaScript/TypeScript \u2014 1871 \u0432\u0430\u043a.<br>C# \u2014 321 \u0432\u0430\u043a.",
      "\u0422\u043e\u043f 5 \u044f\u0437\u044b\u043a\u043e\u0432:<br>JavaScript/TypeScript \u2014 7385 \u0432\u0430\u043a.<br>SQL \u2014 7204 \u0432\u0430\u043a.<br>PHP \u2014 6511 \u0432\u0430\u043a.<br>Python \u2014 4251 \u0432\u0430\u043a.<br>Java \u2014 4186 \u0432\u0430\u043a.",
      "\u0422\u043e\u043f 5 \u044f\u0437\u044b\u043a\u043e\u0432:<br>JavaScript/TypeScript \u2014 33335 \u0432\u0430\u043a.<br>PHP \u2014 3661 \u0432\u0430\u043a.<br>GraphQL \u2014 2473 \u0432\u0430\u043a.<br>Java \u2014 2454 \u0432\u0430\u043a.<br>SQL \u2014 2117 \u0432\u0430\u043a.",
      "\u0422\u043e\u043f 5 \u044f\u0437\u044b\u043a\u043e\u0432:<br>JavaScript/TypeScript \u2014 13475 \u0432\u0430\u043a.<br>PHP \u2014 11079 \u0432\u0430\u043a.<br>SQL \u2014 4556 \u0432\u0430\u043a.<br>Python \u2014 1690 \u0432\u0430\u043a.<br>C# \u2014 1304 \u0432\u0430\u043a.",
      "\u0422\u043e\u043f 5 \u044f\u0437\u044b\u043a\u043e\u0432:<br>SQL \u2014 1092 \u0432\u0430\u043a.<br>Python \u2014 219 \u0432\u0430\u043a.<br>C# \u2014 178 \u0432\u0430\u043a.<br>Java \u2014 172 \u0432\u0430\u043a.<br>JavaScript/TypeScript \u2014 82 \u0432\u0430\u043a.",
      "\u0422\u043e\u043f 5 \u044f\u0437\u044b\u043a\u043e\u0432:<br>Java \u2014 1039 \u0432\u0430\u043a.<br>Swift \u2014 994 \u0432\u0430\u043a.<br>Kotlin \u2014 967 \u0432\u0430\u043a.<br>JavaScript/TypeScript \u2014 612 \u0432\u0430\u043a.<br>Objective-C \u2014 537 \u0432\u0430\u043a.",
      "\u0422\u043e\u043f 5 \u044f\u0437\u044b\u043a\u043e\u0432:<br>C++ \u2014 216 \u0432\u0430\u043a.<br>Python \u2014 156 \u0432\u0430\u043a.<br>C \u2014 122 \u0432\u0430\u043a.<br>Java \u2014 55 \u0432\u0430\u043a.<br>Assembly \u2014 51 \u0432\u0430\u043a.",
      "\u0422\u043e\u043f 5 \u044f\u0437\u044b\u043a\u043e\u0432:<br>SQL \u2014 18742 \u0432\u0430\u043a.<br>Java \u2014 11171 \u0432\u0430\u043a.<br>Python \u2014 7906 \u0432\u0430\u043a.<br>JavaScript/TypeScript \u2014 6640 \u0432\u0430\u043a.<br>C# \u2014 3449 \u0432\u0430\u043a.",
    ],
    hovertemplate:
      "<b>\u0423\u0437\u0435\u043b %{label}</b><br>%{customdata}<extra></extra>",
  },
  link: {
    source: [
      0, 1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 11, 12, 13, 14, 15, 16, 17, 18, 19, 20,
      0, 1, 2, 3, 5, 6, 8, 9, 10, 13, 14, 15, 16, 17, 18, 19, 20, 0, 1, 2, 3, 4,
      5, 6, 7, 8, 9, 10, 11, 13, 14, 15, 16, 17, 18, 19, 20, 1, 2, 3, 5, 6, 7,
      8, 9, 10, 13, 14, 15, 16, 17, 18, 19, 20, 0, 1, 2, 3, 4, 5, 6, 7, 8, 9,
      10, 11, 12, 13, 14, 15, 16, 17, 18, 19, 20, 1, 2, 3, 5, 8, 9, 10, 11, 12,
      13, 14, 15, 16, 17, 18, 19, 20, 21, 0, 1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 11,
      12, 13, 14, 15, 16, 17, 18, 19, 20, 0, 1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 11,
      12, 13, 14, 15, 16, 17, 18, 19, 20, 0, 1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 11,
      12, 13, 14, 15, 16, 17, 18, 19, 20, 1, 2, 3, 5, 6, 8, 9, 10, 12, 13, 14,
      15, 16, 17, 18, 19, 20, 0, 1, 2, 3, 5, 6, 7, 8, 9, 10, 11, 13, 14, 15, 17,
      18, 19, 20, 0, 1, 2, 3, 5, 8, 9, 10, 11, 12, 13, 14, 15, 17, 18, 0, 1, 2,
      3, 4, 5, 6, 7, 8, 9, 10, 11, 12, 13, 14, 15, 16, 17, 18, 19, 20,
    ],
    target: [
      22, 22, 22, 22, 22, 22, 22, 22, 22, 22, 22, 22, 22, 22, 22, 22, 22, 22,
      22, 22, 22, 23, 23, 23, 23, 23, 23, 23, 23, 23, 23, 23, 23, 23, 23, 23,
      23, 23, 24, 24, 24, 24, 24, 24, 24, 24, 24, 24, 24, 24, 24, 24, 24, 24,
      24, 24, 24, 24, 25, 25, 25, 25, 25, 25, 25, 25, 25, 25, 25, 25, 25, 25,
      25, 25, 25, 26, 26, 26, 26, 26, 26, 26, 26, 26, 26, 26, 26, 26, 26, 26,
      26, 26, 26, 26, 26, 26, 27, 27, 27, 27, 27, 27, 27, 27, 27, 27, 27, 27,
      27, 27, 27, 27, 27, 27, 28, 28, 28, 28, 28, 28, 28, 28, 28, 28, 28, 28,
      28, 28, 28, 28, 28, 28, 28, 28, 28, 29, 29, 29, 29, 29, 29, 29, 29, 29,
      29, 29, 29, 29, 29, 29, 29, 29, 29, 29, 29, 29, 30, 30, 30, 30, 30, 30,
      30, 30, 30, 30, 30, 30, 30, 30, 30, 30, 30, 30, 30, 30, 30, 31, 31, 31,
      31, 31, 31, 31, 31, 31, 31, 31, 31, 31, 31, 31, 31, 31, 32, 32, 32, 32,
      32, 32, 32, 32, 32, 32, 32, 32, 32, 32, 32, 32, 32, 32, 33, 33, 33, 33,
      33, 33, 33, 33, 33, 33, 33, 33, 33, 33, 33, 34, 34, 34, 34, 34, 34, 34,
      34, 34, 34, 34, 34, 34, 34, 34, 34, 34, 34, 34, 34, 34,
    ],
    value: [
      39, 98, 577, 889, 20, 441, 131, 17, 2753, 2266, 231, 22, 12, 125, 620,
      7768, 757, 95, 27310, 220, 150, 1, 8, 68, 51, 75, 4, 164, 164, 8, 16, 56,
      3329, 234, 8, 4180, 1, 12, 4, 18, 146, 151, 2, 239, 7, 2, 1246, 187, 37,
      1, 11, 114, 3047, 81, 32, 3194, 5, 16, 49, 363, 141, 95, 3, 3, 420, 156,
      31, 14, 14, 4429, 391, 8, 2714, 11, 3, 78, 141, 771, 801, 29, 3076, 37,
      10, 3909, 1886, 391, 18, 5, 845, 2088, 10551, 11, 1155, 3777, 151, 6, 12,
      202, 321, 46, 252, 1871, 29, 4, 22, 9, 2252, 213, 18, 15, 7080, 13, 26,
      45451, 25, 178, 989, 2435, 66, 3010, 882, 16, 4186, 7385, 1015, 5, 1, 83,
      6511, 4251, 81, 714, 7204, 96, 2, 31, 30, 419, 1186, 8, 1099, 2473, 4,
      2454, 33335, 410, 87, 8, 61, 3661, 1765, 10, 488, 2117, 260, 1, 4, 62,
      293, 1304, 6, 463, 225, 1, 1094, 13475, 111, 16, 11, 82, 11079, 1690, 11,
      345, 4556, 84, 12, 12, 48, 178, 25, 2, 172, 82, 1, 1, 8, 62, 219, 1, 3,
      1092, 2, 7, 9, 21, 170, 328, 51, 70, 2, 1039, 612, 967, 537, 7, 99, 142,
      17, 275, 994, 1, 51, 122, 216, 13, 47, 55, 29, 11, 1, 2, 23, 12, 156, 18,
      48, 32, 153, 1056, 3449, 42, 1243, 323, 1, 11171, 6640, 1952, 118, 5, 208,
      1851, 7906, 17, 584, 18742, 772, 33,
    ],
    color: "rgba(127, 127, 127, 0.1)",
  },
};

const sankeyData: IThread[] = [];

for (let i = 0; i < rawFPData.link.value.length; i++) {
  const thread = {
    path: `${rawFPData.node.label[rawFPData.link.source[i]]} -> ${
      rawFPData.node.label[rawFPData.link.target[i]]
    }`,
    source: rawFPData.node.label[rawFPData.link.source[i]],
    target: rawFPData.node.label[rawFPData.link.target[i]],
    value: rawFPData.link.value[i],
  };
  sankeyData.push(thread);
}

export default sankeyData;
