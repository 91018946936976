import { createSlice, PayloadAction } from "@reduxjs/toolkit";

export interface IAppState {
  isLoading: string;
}

const initialState: IAppState = {
  isLoading: "",
};

const appSlice = createSlice({
  name: "app",
  initialState,
  reducers: {
    loading(state, action: PayloadAction<boolean | string>) {
      switch (typeof action.payload) {
        case "boolean":
          return { isLoading: action.payload ? "Загрузка данных" : "" };
        case "string":
          return { isLoading: action.payload };
        default:
          return state;
      }
    },
  },
});

const { actions, reducer } = appSlice;

export const { loading } = actions;

export default reducer;
