import sankeyFP from "../../dataset/sankeyFP";
import sankeyInd from "../../dataset/sankeyInd";

import { useDispatch } from "react-redux";
import { setCode } from "../../store/code/code.reducer";

import { FilterWrapperRadio, FilterRadio } from "./Filter.styled";

const Filters = () => {
  const dispatch = useDispatch();

  const handleChange = (value: string) => {
    value === "fp" ? dispatch(setCode(sankeyFP)) : dispatch(setCode(sankeyInd));
  };

  return (
    <FilterWrapperRadio
      defaultValue="fp"
      optionType="button"
      buttonStyle="solid"
      onChange={(e) => handleChange(e.target.value)}
    >
      <FilterRadio value="fp">Функциональные позиции</FilterRadio>
      <FilterRadio value="ind">Отрасли экономики</FilterRadio>
    </FilterWrapperRadio>
  );
};

export default Filters;
