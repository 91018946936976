import { configureStore } from "@reduxjs/toolkit";

import appReducer from "./app/app.reducer";
import codeReducer from "./code/code.reducer";

export const middleware = (getDefaultMiddleware: any) => {
  return getDefaultMiddleware({ immutableCheck: true, thunk: true });
};

export const reducer = {
  app: appReducer,
  code: codeReducer,
};

export const store = configureStore({
  reducer,
  middleware,
  devTools: process.env.NODE_ENV === "development",
});

export type RootState = ReturnType<typeof store.getState>;

export type AppDispatch = typeof store.dispatch;
