import { IThread } from "../store/code/code.reducer";

const rawIndData = {
  node: {
    thickness: 20,
    line: { color: "black", width: 0.5 },
    label: [
      "Assembly",
      "C",
      "C++",
      "C#",
      "Erlang",
      "Go",
      "GraphQL",
      "Java",
      "JavaScript/TypeScript",
      "Kotlin",
      "Objective-C",
      "Pascal",
      "Perl",
      "PHP",
      "Python",
      "R",
      "Ruby",
      "SQL",
      "Swift",
      "Visual Basic",
      "Haskell",
      "\u041f\u0435\u0440\u0435\u0432\u043e\u0437\u043a\u0438, \u043b\u043e\u0433\u0438\u0441\u0442\u0438\u043a\u0430, \u0441\u043a\u043b\u0430\u0434, \u0412\u042d\u0414",
      "\u0418\u043d\u0444\u043e\u0440\u043c\u0430\u0446\u0438\u043e\u043d\u043d\u044b\u0435 \u0442\u0435\u0445\u043d\u043e\u043b\u043e\u0433\u0438\u0438, \u0441\u0438\u0441\u0442\u0435\u043c\u043d\u0430\u044f \u0438\u043d\u0442\u0435\u0433\u0440\u0430\u0446\u0438\u044f, \u0438\u043d\u0442\u0435\u0440\u043d\u0435\u0442",
      "\u042d\u043b\u0435\u043a\u0442\u0440\u043e\u043d\u0438\u043a\u0430, \u043f\u0440\u0438\u0431\u043e\u0440\u043e\u0441\u0442\u0440\u043e\u0435\u043d\u0438\u0435, \u0431\u044b\u0442\u043e\u0432\u0430\u044f \u0442\u0435\u0445\u043d\u0438\u043a\u0430, \u043a\u043e\u043c\u043f\u044c\u044e\u0442\u0435\u0440\u044b \u0438 \u043e\u0440\u0433\u0442\u0435\u0445\u043d\u0438\u043a\u0430",
      "\u0422\u0435\u043b\u0435\u043a\u043e\u043c\u043c\u0443\u043d\u0438\u043a\u0430\u0446\u0438\u0438, \u0441\u0432\u044f\u0437\u044c",
      "\u0421\u041c\u0418, \u043c\u0430\u0440\u043a\u0435\u0442\u0438\u043d\u0433, \u0440\u0435\u043a\u043b\u0430\u043c\u0430, BTL, PR, \u0434\u0438\u0437\u0430\u0439\u043d, \u043f\u0440\u043e\u0434\u044e\u0441\u0438\u0440\u043e\u0432\u0430\u043d\u0438\u0435",
      "\u0421\u0442\u0440\u043e\u0438\u0442\u0435\u043b\u044c\u0441\u0442\u0432\u043e, \u043d\u0435\u0434\u0432\u0438\u0436\u0438\u043c\u043e\u0441\u0442\u044c, \u044d\u043a\u0441\u043f\u043b\u0443\u0430\u0442\u0430\u0446\u0438\u044f, \u043f\u0440\u043e\u0435\u043a\u0442\u0438\u0440\u043e\u0432\u0430\u043d\u0438\u0435",
      "\u0410\u0432\u0442\u043e\u043c\u043e\u0431\u0438\u043b\u044c\u043d\u044b\u0439 \u0431\u0438\u0437\u043d\u0435\u0441",
      "\u041b\u0435\u0441\u043d\u0430\u044f \u043f\u0440\u043e\u043c\u044b\u0448\u043b\u0435\u043d\u043d\u043e\u0441\u0442\u044c, \u0434\u0435\u0440\u0435\u0432\u043e\u043e\u0431\u0440\u0430\u0431\u043e\u0442\u043a\u0430",
      "\u041c\u0435\u0442\u0430\u043b\u043b\u0443\u0440\u0433\u0438\u044f, \u043c\u0435\u0442\u0430\u043b\u043b\u043e\u043e\u0431\u0440\u0430\u0431\u043e\u0442\u043a\u0430",
      "\u041f\u0440\u043e\u0434\u0443\u043a\u0442\u044b \u043f\u0438\u0442\u0430\u043d\u0438\u044f",
      "\u0421\u0435\u043b\u044c\u0441\u043a\u043e\u0435 \u0445\u043e\u0437\u044f\u0439\u0441\u0442\u0432\u043e",
      "\u0422\u044f\u0436\u0435\u043b\u043e\u0435 \u043c\u0430\u0448\u0438\u043d\u043e\u0441\u0442\u0440\u043e\u0435\u043d\u0438\u0435",
      "\u0425\u0438\u043c\u0438\u0447\u0435\u0441\u043a\u043e\u0435 \u043f\u0440\u043e\u0438\u0437\u0432\u043e\u0434\u0441\u0442\u0432\u043e, \u0443\u0434\u043e\u0431\u0440\u0435\u043d\u0438\u044f",
      "\u0413\u043e\u0441\u0443\u0434\u0430\u0440\u0441\u0442\u0432\u0435\u043d\u043d\u044b\u0435 \u043e\u0440\u0433\u0430\u043d\u0438\u0437\u0430\u0446\u0438\u0438",
      "\u041e\u0431\u0449\u0435\u0441\u0442\u0432\u0435\u043d\u043d\u0430\u044f \u0434\u0435\u044f\u0442\u0435\u043b\u044c\u043d\u043e\u0441\u0442\u044c, \u043f\u0430\u0440\u0442\u0438\u0438, \u0431\u043b\u0430\u0433\u043e\u0442\u0432\u043e\u0440\u0438\u0442\u0435\u043b\u044c\u043d\u043e\u0441\u0442\u044c, \u041d\u041a\u041e",
      "\u041e\u0431\u0440\u0430\u0437\u043e\u0432\u0430\u0442\u0435\u043b\u044c\u043d\u044b\u0435 \u0443\u0447\u0440\u0435\u0436\u0434\u0435\u043d\u0438\u044f",
      "\u0420\u043e\u0437\u043d\u0438\u0447\u043d\u0430\u044f \u0442\u043e\u0440\u0433\u043e\u0432\u043b\u044f",
      "\u0422\u043e\u0432\u0430\u0440\u044b \u043d\u0430\u0440\u043e\u0434\u043d\u043e\u0433\u043e \u043f\u043e\u0442\u0440\u0435\u0431\u043b\u0435\u043d\u0438\u044f (\u043d\u0435\u043f\u0438\u0449\u0435\u0432\u044b\u0435)",
      "\u0424\u0438\u043d\u0430\u043d\u0441\u043e\u0432\u044b\u0439 \u0441\u0435\u043a\u0442\u043e\u0440",
      "\u0423\u0441\u043b\u0443\u0433\u0438 \u0434\u043b\u044f \u0431\u0438\u0437\u043d\u0435\u0441\u0430",
      "\u0414\u043e\u0431\u044b\u0432\u0430\u044e\u0449\u0430\u044f \u043e\u0442\u0440\u0430\u0441\u043b\u044c",
      "\u042d\u043d\u0435\u0440\u0433\u0435\u0442\u0438\u043a\u0430",
      "\u041d\u0435\u0444\u0442\u044c \u0438 \u0433\u0430\u0437",
      "\u041c\u0435\u0434\u0438\u0446\u0438\u043d\u0430, \u0444\u0430\u0440\u043c\u0430\u0446\u0435\u0432\u0442\u0438\u043a\u0430, \u0430\u043f\u0442\u0435\u043a\u0438",
      "\u0423\u0441\u043b\u0443\u0433\u0438 \u0434\u043b\u044f \u043d\u0430\u0441\u0435\u043b\u0435\u043d\u0438\u044f",
      "\u0413\u043e\u0441\u0442\u0438\u043d\u0438\u0446\u044b, \u0440\u0435\u0441\u0442\u043e\u0440\u0430\u043d\u044b, \u043e\u0431\u0449\u0435\u043f\u0438\u0442, \u043a\u0435\u0439\u0442\u0435\u0440\u0438\u043d\u0433",
      "\u0416\u041a\u0425",
      "\u0418\u0441\u043a\u0443\u0441\u0441\u0442\u0432\u043e, \u043a\u0443\u043b\u044c\u0442\u0443\u0440\u0430",
      "\u041f\u0440\u043e\u043c\u044b\u0448\u043b\u0435\u043d\u043d\u043e\u0435 \u043e\u0431\u043e\u0440\u0443\u0434\u043e\u0432\u0430\u043d\u0438\u0435, \u0442\u0435\u0445\u043d\u0438\u043a\u0430, \u0441\u0442\u0430\u043d\u043a\u0438 \u0438 \u043a\u043e\u043c\u043f\u043b\u0435\u043a\u0442\u0443\u044e\u0449\u0438\u0435",
      "\u0423\u043f\u0440\u0430\u0432\u043b\u0435\u043d\u0438\u0435 \u043c\u043d\u043e\u0433\u043e\u043f\u0440\u043e\u0444\u0438\u043b\u044c\u043d\u044b\u043c\u0438 \u0430\u043a\u0442\u0438\u0432\u0430\u043c\u0438",
    ],
    customdata: [
      "\u0422\u043e\u043f 5 \u043e\u0442\u0440\u0430\u0441\u043b\u0435\u0439:<br>\u0418\u043d\u0444\u043e\u0440\u043c\u0430\u0446\u0438\u043e\u043d\u043d\u044b\u0435 \u0442\u0435\u0445\u043d\u043e\u043b\u043e\u0433\u0438\u0438, \u0441\u0438\u0441\u0442\u0435\u043c\u043d\u0430\u044f \u0438\u043d\u0442\u0435\u0433\u0440\u0430\u0446\u0438\u044f, \u0438\u043d\u0442\u0435\u0440\u043d\u0435\u0442 \u2014 826 \u0432\u0430\u043a.<br>\u042d\u043b\u0435\u043a\u0442\u0440\u043e\u043d\u0438\u043a\u0430, \u043f\u0440\u0438\u0431\u043e\u0440\u043e\u0441\u0442\u0440\u043e\u0435\u043d\u0438\u0435, \u0431\u044b\u0442\u043e\u0432\u0430\u044f \u0442\u0435\u0445\u043d\u0438\u043a\u0430, \u043a\u043e\u043c\u043f\u044c\u044e\u0442\u0435\u0440\u044b \u0438 \u043e\u0440\u0433\u0442\u0435\u0445\u043d\u0438\u043a\u0430 \u2014 186 \u0432\u0430\u043a.<br>\u0410\u0432\u0442\u043e\u043c\u043e\u0431\u0438\u043b\u044c\u043d\u044b\u0439 \u0431\u0438\u0437\u043d\u0435\u0441 \u2014 145 \u0432\u0430\u043a.<br>\u0422\u0435\u043b\u0435\u043a\u043e\u043c\u043c\u0443\u043d\u0438\u043a\u0430\u0446\u0438\u0438, \u0441\u0432\u044f\u0437\u044c \u2014 94 \u0432\u0430\u043a.<br>\u0423\u0441\u043b\u0443\u0433\u0438 \u0434\u043b\u044f \u0431\u0438\u0437\u043d\u0435\u0441\u0430 \u2014 49 \u0432\u0430\u043a.",
      "\u0422\u043e\u043f 5 \u043e\u0442\u0440\u0430\u0441\u043b\u0435\u0439:<br>\u0418\u043d\u0444\u043e\u0440\u043c\u0430\u0446\u0438\u043e\u043d\u043d\u044b\u0435 \u0442\u0435\u0445\u043d\u043e\u043b\u043e\u0433\u0438\u0438, \u0441\u0438\u0441\u0442\u0435\u043c\u043d\u0430\u044f \u0438\u043d\u0442\u0435\u0433\u0440\u0430\u0446\u0438\u044f, \u0438\u043d\u0442\u0435\u0440\u043d\u0435\u0442 \u2014 4471 \u0432\u0430\u043a.<br>\u042d\u043b\u0435\u043a\u0442\u0440\u043e\u043d\u0438\u043a\u0430, \u043f\u0440\u0438\u0431\u043e\u0440\u043e\u0441\u0442\u0440\u043e\u0435\u043d\u0438\u0435, \u0431\u044b\u0442\u043e\u0432\u0430\u044f \u0442\u0435\u0445\u043d\u0438\u043a\u0430, \u043a\u043e\u043c\u043f\u044c\u044e\u0442\u0435\u0440\u044b \u0438 \u043e\u0440\u0433\u0442\u0435\u0445\u043d\u0438\u043a\u0430 \u2014 1226 \u0432\u0430\u043a.<br>\u0422\u0435\u043b\u0435\u043a\u043e\u043c\u043c\u0443\u043d\u0438\u043a\u0430\u0446\u0438\u0438, \u0441\u0432\u044f\u0437\u044c \u2014 647 \u0432\u0430\u043a.<br>\u0423\u0441\u043b\u0443\u0433\u0438 \u0434\u043b\u044f \u0431\u0438\u0437\u043d\u0435\u0441\u0430 \u2014 520 \u0432\u0430\u043a.<br>\u0424\u0438\u043d\u0430\u043d\u0441\u043e\u0432\u044b\u0439 \u0441\u0435\u043a\u0442\u043e\u0440 \u2014 294 \u0432\u0430\u043a.",
      "\u0422\u043e\u043f 5 \u043e\u0442\u0440\u0430\u0441\u043b\u0435\u0439:<br>\u0418\u043d\u0444\u043e\u0440\u043c\u0430\u0446\u0438\u043e\u043d\u043d\u044b\u0435 \u0442\u0435\u0445\u043d\u043e\u043b\u043e\u0433\u0438\u0438, \u0441\u0438\u0441\u0442\u0435\u043c\u043d\u0430\u044f \u0438\u043d\u0442\u0435\u0433\u0440\u0430\u0446\u0438\u044f, \u0438\u043d\u0442\u0435\u0440\u043d\u0435\u0442 \u2014 20816 \u0432\u0430\u043a.<br>\u042d\u043b\u0435\u043a\u0442\u0440\u043e\u043d\u0438\u043a\u0430, \u043f\u0440\u0438\u0431\u043e\u0440\u043e\u0441\u0442\u0440\u043e\u0435\u043d\u0438\u0435, \u0431\u044b\u0442\u043e\u0432\u0430\u044f \u0442\u0435\u0445\u043d\u0438\u043a\u0430, \u043a\u043e\u043c\u043f\u044c\u044e\u0442\u0435\u0440\u044b \u0438 \u043e\u0440\u0433\u0442\u0435\u0445\u043d\u0438\u043a\u0430 \u2014 3558 \u0432\u0430\u043a.<br>\u0424\u0438\u043d\u0430\u043d\u0441\u043e\u0432\u044b\u0439 \u0441\u0435\u043a\u0442\u043e\u0440 \u2014 2510 \u0432\u0430\u043a.<br>\u0423\u0441\u043b\u0443\u0433\u0438 \u0434\u043b\u044f \u0431\u0438\u0437\u043d\u0435\u0441\u0430 \u2014 2069 \u0432\u0430\u043a.<br>\u0422\u0435\u043b\u0435\u043a\u043e\u043c\u043c\u0443\u043d\u0438\u043a\u0430\u0446\u0438\u0438, \u0441\u0432\u044f\u0437\u044c \u2014 1699 \u0432\u0430\u043a.",
      "\u0422\u043e\u043f 5 \u043e\u0442\u0440\u0430\u0441\u043b\u0435\u0439:<br>\u0418\u043d\u0444\u043e\u0440\u043c\u0430\u0446\u0438\u043e\u043d\u043d\u044b\u0435 \u0442\u0435\u0445\u043d\u043e\u043b\u043e\u0433\u0438\u0438, \u0441\u0438\u0441\u0442\u0435\u043c\u043d\u0430\u044f \u0438\u043d\u0442\u0435\u0433\u0440\u0430\u0446\u0438\u044f, \u0438\u043d\u0442\u0435\u0440\u043d\u0435\u0442 \u2014 28636 \u0432\u0430\u043a.<br>\u0424\u0438\u043d\u0430\u043d\u0441\u043e\u0432\u044b\u0439 \u0441\u0435\u043a\u0442\u043e\u0440 \u2014 4592 \u0432\u0430\u043a.<br>\u0423\u0441\u043b\u0443\u0433\u0438 \u0434\u043b\u044f \u0431\u0438\u0437\u043d\u0435\u0441\u0430 \u2014 3145 \u0432\u0430\u043a.<br>\u0420\u043e\u0437\u043d\u0438\u0447\u043d\u0430\u044f \u0442\u043e\u0440\u0433\u043e\u0432\u043b\u044f \u2014 2054 \u0432\u0430\u043a.<br>\u042d\u043b\u0435\u043a\u0442\u0440\u043e\u043d\u0438\u043a\u0430, \u043f\u0440\u0438\u0431\u043e\u0440\u043e\u0441\u0442\u0440\u043e\u0435\u043d\u0438\u0435, \u0431\u044b\u0442\u043e\u0432\u0430\u044f \u0442\u0435\u0445\u043d\u0438\u043a\u0430, \u043a\u043e\u043c\u043f\u044c\u044e\u0442\u0435\u0440\u044b \u0438 \u043e\u0440\u0433\u0442\u0435\u0445\u043d\u0438\u043a\u0430 \u2014 1653 \u0432\u0430\u043a.",
      "\u0422\u043e\u043f 5 \u043e\u0442\u0440\u0430\u0441\u043b\u0435\u0439:<br>\u0418\u043d\u0444\u043e\u0440\u043c\u0430\u0446\u0438\u043e\u043d\u043d\u044b\u0435 \u0442\u0435\u0445\u043d\u043e\u043b\u043e\u0433\u0438\u0438, \u0441\u0438\u0441\u0442\u0435\u043c\u043d\u0430\u044f \u0438\u043d\u0442\u0435\u0433\u0440\u0430\u0446\u0438\u044f, \u0438\u043d\u0442\u0435\u0440\u043d\u0435\u0442 \u2014 547 \u0432\u0430\u043a.<br>\u0423\u0441\u043b\u0443\u0433\u0438 \u0434\u043b\u044f \u0431\u0438\u0437\u043d\u0435\u0441\u0430 \u2014 54 \u0432\u0430\u043a.<br>\u0424\u0438\u043d\u0430\u043d\u0441\u043e\u0432\u044b\u0439 \u0441\u0435\u043a\u0442\u043e\u0440 \u2014 30 \u0432\u0430\u043a.<br>\u0422\u0435\u043b\u0435\u043a\u043e\u043c\u043c\u0443\u043d\u0438\u043a\u0430\u0446\u0438\u0438, \u0441\u0432\u044f\u0437\u044c \u2014 17 \u0432\u0430\u043a.<br>\u042d\u043b\u0435\u043a\u0442\u0440\u043e\u043d\u0438\u043a\u0430, \u043f\u0440\u0438\u0431\u043e\u0440\u043e\u0441\u0442\u0440\u043e\u0435\u043d\u0438\u0435, \u0431\u044b\u0442\u043e\u0432\u0430\u044f \u0442\u0435\u0445\u043d\u0438\u043a\u0430, \u043a\u043e\u043c\u043f\u044c\u044e\u0442\u0435\u0440\u044b \u0438 \u043e\u0440\u0433\u0442\u0435\u0445\u043d\u0438\u043a\u0430 \u2014 15 \u0432\u0430\u043a.",
      "\u0422\u043e\u043f 5 \u043e\u0442\u0440\u0430\u0441\u043b\u0435\u0439:<br>\u0418\u043d\u0444\u043e\u0440\u043c\u0430\u0446\u0438\u043e\u043d\u043d\u044b\u0435 \u0442\u0435\u0445\u043d\u043e\u043b\u043e\u0433\u0438\u0438, \u0441\u0438\u0441\u0442\u0435\u043c\u043d\u0430\u044f \u0438\u043d\u0442\u0435\u0433\u0440\u0430\u0446\u0438\u044f, \u0438\u043d\u0442\u0435\u0440\u043d\u0435\u0442 \u2014 19760 \u0432\u0430\u043a.<br>\u0424\u0438\u043d\u0430\u043d\u0441\u043e\u0432\u044b\u0439 \u0441\u0435\u043a\u0442\u043e\u0440 \u2014 2336 \u0432\u0430\u043a.<br>\u0420\u043e\u0437\u043d\u0438\u0447\u043d\u0430\u044f \u0442\u043e\u0440\u0433\u043e\u0432\u043b\u044f \u2014 2251 \u0432\u0430\u043a.<br>\u0421\u041c\u0418, \u043c\u0430\u0440\u043a\u0435\u0442\u0438\u043d\u0433, \u0440\u0435\u043a\u043b\u0430\u043c\u0430, BTL, PR, \u0434\u0438\u0437\u0430\u0439\u043d, \u043f\u0440\u043e\u0434\u044e\u0441\u0438\u0440\u043e\u0432\u0430\u043d\u0438\u0435 \u2014 2039 \u0432\u0430\u043a.<br>\u0423\u0441\u043b\u0443\u0433\u0438 \u0434\u043b\u044f \u0431\u0438\u0437\u043d\u0435\u0441\u0430 \u2014 1673 \u0432\u0430\u043a.",
      "\u0422\u043e\u043f 5 \u043e\u0442\u0440\u0430\u0441\u043b\u0435\u0439:<br>\u0418\u043d\u0444\u043e\u0440\u043c\u0430\u0446\u0438\u043e\u043d\u043d\u044b\u0435 \u0442\u0435\u0445\u043d\u043e\u043b\u043e\u0433\u0438\u0438, \u0441\u0438\u0441\u0442\u0435\u043c\u043d\u0430\u044f \u0438\u043d\u0442\u0435\u0433\u0440\u0430\u0446\u0438\u044f, \u0438\u043d\u0442\u0435\u0440\u043d\u0435\u0442 \u2014 5110 \u0432\u0430\u043a.<br>\u0424\u0438\u043d\u0430\u043d\u0441\u043e\u0432\u044b\u0439 \u0441\u0435\u043a\u0442\u043e\u0440 \u2014 591 \u0432\u0430\u043a.<br>\u0423\u0441\u043b\u0443\u0433\u0438 \u0434\u043b\u044f \u0431\u0438\u0437\u043d\u0435\u0441\u0430 \u2014 512 \u0432\u0430\u043a.<br>\u0421\u041c\u0418, \u043c\u0430\u0440\u043a\u0435\u0442\u0438\u043d\u0433, \u0440\u0435\u043a\u043b\u0430\u043c\u0430, BTL, PR, \u0434\u0438\u0437\u0430\u0439\u043d, \u043f\u0440\u043e\u0434\u044e\u0441\u0438\u0440\u043e\u0432\u0430\u043d\u0438\u0435 \u2014 421 \u0432\u0430\u043a.<br>\u0420\u043e\u0437\u043d\u0438\u0447\u043d\u0430\u044f \u0442\u043e\u0440\u0433\u043e\u0432\u043b\u044f \u2014 346 \u0432\u0430\u043a.",
      "\u0422\u043e\u043f 5 \u043e\u0442\u0440\u0430\u0441\u043b\u0435\u0439:<br>\u0418\u043d\u0444\u043e\u0440\u043c\u0430\u0446\u0438\u043e\u043d\u043d\u044b\u0435 \u0442\u0435\u0445\u043d\u043e\u043b\u043e\u0433\u0438\u0438, \u0441\u0438\u0441\u0442\u0435\u043c\u043d\u0430\u044f \u0438\u043d\u0442\u0435\u0433\u0440\u0430\u0446\u0438\u044f, \u0438\u043d\u0442\u0435\u0440\u043d\u0435\u0442 \u2014 56892 \u0432\u0430\u043a.<br>\u0424\u0438\u043d\u0430\u043d\u0441\u043e\u0432\u044b\u0439 \u0441\u0435\u043a\u0442\u043e\u0440 \u2014 18728 \u0432\u0430\u043a.<br>\u0423\u0441\u043b\u0443\u0433\u0438 \u0434\u043b\u044f \u0431\u0438\u0437\u043d\u0435\u0441\u0430 \u2014 5873 \u0432\u0430\u043a.<br>\u0422\u0435\u043b\u0435\u043a\u043e\u043c\u043c\u0443\u043d\u0438\u043a\u0430\u0446\u0438\u0438, \u0441\u0432\u044f\u0437\u044c \u2014 3614 \u0432\u0430\u043a.<br>\u0420\u043e\u0437\u043d\u0438\u0447\u043d\u0430\u044f \u0442\u043e\u0440\u0433\u043e\u0432\u043b\u044f \u2014 3206 \u0432\u0430\u043a.",
      "\u0422\u043e\u043f 5 \u043e\u0442\u0440\u0430\u0441\u043b\u0435\u0439:<br>\u0418\u043d\u0444\u043e\u0440\u043c\u0430\u0446\u0438\u043e\u043d\u043d\u044b\u0435 \u0442\u0435\u0445\u043d\u043e\u043b\u043e\u0433\u0438\u0438, \u0441\u0438\u0441\u0442\u0435\u043c\u043d\u0430\u044f \u0438\u043d\u0442\u0435\u0433\u0440\u0430\u0446\u0438\u044f, \u0438\u043d\u0442\u0435\u0440\u043d\u0435\u0442 \u2014 90400 \u0432\u0430\u043a.<br>\u0424\u0438\u043d\u0430\u043d\u0441\u043e\u0432\u044b\u0439 \u0441\u0435\u043a\u0442\u043e\u0440 \u2014 11854 \u0432\u0430\u043a.<br>\u0423\u0441\u043b\u0443\u0433\u0438 \u0434\u043b\u044f \u0431\u0438\u0437\u043d\u0435\u0441\u0430 \u2014 9751 \u0432\u0430\u043a.<br>\u0421\u041c\u0418, \u043c\u0430\u0440\u043a\u0435\u0442\u0438\u043d\u0433, \u0440\u0435\u043a\u043b\u0430\u043c\u0430, BTL, PR, \u0434\u0438\u0437\u0430\u0439\u043d, \u043f\u0440\u043e\u0434\u044e\u0441\u0438\u0440\u043e\u0432\u0430\u043d\u0438\u0435 \u2014 8379 \u0432\u0430\u043a.<br>\u0420\u043e\u0437\u043d\u0438\u0447\u043d\u0430\u044f \u0442\u043e\u0440\u0433\u043e\u0432\u043b\u044f \u2014 7204 \u0432\u0430\u043a.",
      "\u0422\u043e\u043f 5 \u043e\u0442\u0440\u0430\u0441\u043b\u0435\u0439:<br>\u0418\u043d\u0444\u043e\u0440\u043c\u0430\u0446\u0438\u043e\u043d\u043d\u044b\u0435 \u0442\u0435\u0445\u043d\u043e\u043b\u043e\u0433\u0438\u0438, \u0441\u0438\u0441\u0442\u0435\u043c\u043d\u0430\u044f \u0438\u043d\u0442\u0435\u0433\u0440\u0430\u0446\u0438\u044f, \u0438\u043d\u0442\u0435\u0440\u043d\u0435\u0442 \u2014 14833 \u0432\u0430\u043a.<br>\u0424\u0438\u043d\u0430\u043d\u0441\u043e\u0432\u044b\u0439 \u0441\u0435\u043a\u0442\u043e\u0440 \u2014 3110 \u0432\u0430\u043a.<br>\u0423\u0441\u043b\u0443\u0433\u0438 \u0434\u043b\u044f \u0431\u0438\u0437\u043d\u0435\u0441\u0430 \u2014 1398 \u0432\u0430\u043a.<br>\u0420\u043e\u0437\u043d\u0438\u0447\u043d\u0430\u044f \u0442\u043e\u0440\u0433\u043e\u0432\u043b\u044f \u2014 1213 \u0432\u0430\u043a.<br>\u0421\u041c\u0418, \u043c\u0430\u0440\u043a\u0435\u0442\u0438\u043d\u0433, \u0440\u0435\u043a\u043b\u0430\u043c\u0430, BTL, PR, \u0434\u0438\u0437\u0430\u0439\u043d, \u043f\u0440\u043e\u0434\u044e\u0441\u0438\u0440\u043e\u0432\u0430\u043d\u0438\u0435 \u2014 803 \u0432\u0430\u043a.",
      "\u0422\u043e\u043f 5 \u043e\u0442\u0440\u0430\u0441\u043b\u0435\u0439:<br>\u0418\u043d\u0444\u043e\u0440\u043c\u0430\u0446\u0438\u043e\u043d\u043d\u044b\u0435 \u0442\u0435\u0445\u043d\u043e\u043b\u043e\u0433\u0438\u0438, \u0441\u0438\u0441\u0442\u0435\u043c\u043d\u0430\u044f \u0438\u043d\u0442\u0435\u0433\u0440\u0430\u0446\u0438\u044f, \u0438\u043d\u0442\u0435\u0440\u043d\u0435\u0442 \u2014 4527 \u0432\u0430\u043a.<br>\u0424\u0438\u043d\u0430\u043d\u0441\u043e\u0432\u044b\u0439 \u0441\u0435\u043a\u0442\u043e\u0440 \u2014 648 \u0432\u0430\u043a.<br>\u0423\u0441\u043b\u0443\u0433\u0438 \u0434\u043b\u044f \u0431\u0438\u0437\u043d\u0435\u0441\u0430 \u2014 357 \u0432\u0430\u043a.<br>\u0421\u041c\u0418, \u043c\u0430\u0440\u043a\u0435\u0442\u0438\u043d\u0433, \u0440\u0435\u043a\u043b\u0430\u043c\u0430, BTL, PR, \u0434\u0438\u0437\u0430\u0439\u043d, \u043f\u0440\u043e\u0434\u044e\u0441\u0438\u0440\u043e\u0432\u0430\u043d\u0438\u0435 \u2014 336 \u0432\u0430\u043a.<br>\u0420\u043e\u0437\u043d\u0438\u0447\u043d\u0430\u044f \u0442\u043e\u0440\u0433\u043e\u0432\u043b\u044f \u2014 232 \u0432\u0430\u043a.",
      "\u0422\u043e\u043f 5 \u043e\u0442\u0440\u0430\u0441\u043b\u0435\u0439:<br>\u0418\u043d\u0444\u043e\u0440\u043c\u0430\u0446\u0438\u043e\u043d\u043d\u044b\u0435 \u0442\u0435\u0445\u043d\u043e\u043b\u043e\u0433\u0438\u0438, \u0441\u0438\u0441\u0442\u0435\u043c\u043d\u0430\u044f \u0438\u043d\u0442\u0435\u0433\u0440\u0430\u0446\u0438\u044f, \u0438\u043d\u0442\u0435\u0440\u043d\u0435\u0442 \u2014 296 \u0432\u0430\u043a.<br>\u0421\u0442\u0440\u043e\u0438\u0442\u0435\u043b\u044c\u0441\u0442\u0432\u043e, \u043d\u0435\u0434\u0432\u0438\u0436\u0438\u043c\u043e\u0441\u0442\u044c, \u044d\u043a\u0441\u043f\u043b\u0443\u0430\u0442\u0430\u0446\u0438\u044f, \u043f\u0440\u043e\u0435\u043a\u0442\u0438\u0440\u043e\u0432\u0430\u043d\u0438\u0435 \u2014 56 \u0432\u0430\u043a.<br>\u041f\u0440\u043e\u043c\u044b\u0448\u043b\u0435\u043d\u043d\u043e\u0435 \u043e\u0431\u043e\u0440\u0443\u0434\u043e\u0432\u0430\u043d\u0438\u0435, \u0442\u0435\u0445\u043d\u0438\u043a\u0430, \u0441\u0442\u0430\u043d\u043a\u0438 \u0438 \u043a\u043e\u043c\u043f\u043b\u0435\u043a\u0442\u0443\u044e\u0449\u0438\u0435 \u2014 54 \u0432\u0430\u043a.<br>\u0424\u0438\u043d\u0430\u043d\u0441\u043e\u0432\u044b\u0439 \u0441\u0435\u043a\u0442\u043e\u0440 \u2014 50 \u0432\u0430\u043a.<br>\u042d\u043b\u0435\u043a\u0442\u0440\u043e\u043d\u0438\u043a\u0430, \u043f\u0440\u0438\u0431\u043e\u0440\u043e\u0441\u0442\u0440\u043e\u0435\u043d\u0438\u0435, \u0431\u044b\u0442\u043e\u0432\u0430\u044f \u0442\u0435\u0445\u043d\u0438\u043a\u0430, \u043a\u043e\u043c\u043f\u044c\u044e\u0442\u0435\u0440\u044b \u0438 \u043e\u0440\u0433\u0442\u0435\u0445\u043d\u0438\u043a\u0430 \u2014 40 \u0432\u0430\u043a.",
      "\u0422\u043e\u043f 5 \u043e\u0442\u0440\u0430\u0441\u043b\u0435\u0439:<br>\u0418\u043d\u0444\u043e\u0440\u043c\u0430\u0446\u0438\u043e\u043d\u043d\u044b\u0435 \u0442\u0435\u0445\u043d\u043e\u043b\u043e\u0433\u0438\u0438, \u0441\u0438\u0441\u0442\u0435\u043c\u043d\u0430\u044f \u0438\u043d\u0442\u0435\u0433\u0440\u0430\u0446\u0438\u044f, \u0438\u043d\u0442\u0435\u0440\u043d\u0435\u0442 \u2014 3796 \u0432\u0430\u043a.<br>\u0422\u0435\u043b\u0435\u043a\u043e\u043c\u043c\u0443\u043d\u0438\u043a\u0430\u0446\u0438\u0438, \u0441\u0432\u044f\u0437\u044c \u2014 835 \u0432\u0430\u043a.<br>\u0424\u0438\u043d\u0430\u043d\u0441\u043e\u0432\u044b\u0439 \u0441\u0435\u043a\u0442\u043e\u0440 \u2014 637 \u0432\u0430\u043a.<br>\u0421\u041c\u0418, \u043c\u0430\u0440\u043a\u0435\u0442\u0438\u043d\u0433, \u0440\u0435\u043a\u043b\u0430\u043c\u0430, BTL, PR, \u0434\u0438\u0437\u0430\u0439\u043d, \u043f\u0440\u043e\u0434\u044e\u0441\u0438\u0440\u043e\u0432\u0430\u043d\u0438\u0435 \u2014 255 \u0432\u0430\u043a.<br>\u0423\u0441\u043b\u0443\u0433\u0438 \u0434\u043b\u044f \u0431\u0438\u0437\u043d\u0435\u0441\u0430 \u2014 235 \u0432\u0430\u043a.",
      "\u0422\u043e\u043f 5 \u043e\u0442\u0440\u0430\u0441\u043b\u0435\u0439:<br>\u0418\u043d\u0444\u043e\u0440\u043c\u0430\u0446\u0438\u043e\u043d\u043d\u044b\u0435 \u0442\u0435\u0445\u043d\u043e\u043b\u043e\u0433\u0438\u0438, \u0441\u0438\u0441\u0442\u0435\u043c\u043d\u0430\u044f \u0438\u043d\u0442\u0435\u0433\u0440\u0430\u0446\u0438\u044f, \u0438\u043d\u0442\u0435\u0440\u043d\u0435\u0442 \u2014 37136 \u0432\u0430\u043a.<br>\u0421\u041c\u0418, \u043c\u0430\u0440\u043a\u0435\u0442\u0438\u043d\u0433, \u0440\u0435\u043a\u043b\u0430\u043c\u0430, BTL, PR, \u0434\u0438\u0437\u0430\u0439\u043d, \u043f\u0440\u043e\u0434\u044e\u0441\u0438\u0440\u043e\u0432\u0430\u043d\u0438\u0435 \u2014 6021 \u0432\u0430\u043a.<br>\u0420\u043e\u0437\u043d\u0438\u0447\u043d\u0430\u044f \u0442\u043e\u0440\u0433\u043e\u0432\u043b\u044f \u2014 5068 \u0432\u0430\u043a.<br>\u0423\u0441\u043b\u0443\u0433\u0438 \u0434\u043b\u044f \u0431\u0438\u0437\u043d\u0435\u0441\u0430 \u2014 4546 \u0432\u0430\u043a.<br>\u0424\u0438\u043d\u0430\u043d\u0441\u043e\u0432\u044b\u0439 \u0441\u0435\u043a\u0442\u043e\u0440 \u2014 3661 \u0432\u0430\u043a.",
      "\u0422\u043e\u043f 5 \u043e\u0442\u0440\u0430\u0441\u043b\u0435\u0439:<br>\u0418\u043d\u0444\u043e\u0440\u043c\u0430\u0446\u0438\u043e\u043d\u043d\u044b\u0435 \u0442\u0435\u0445\u043d\u043e\u043b\u043e\u0433\u0438\u0438, \u0441\u0438\u0441\u0442\u0435\u043c\u043d\u0430\u044f \u0438\u043d\u0442\u0435\u0433\u0440\u0430\u0446\u0438\u044f, \u0438\u043d\u0442\u0435\u0440\u043d\u0435\u0442 \u2014 60598 \u0432\u0430\u043a.<br>\u0424\u0438\u043d\u0430\u043d\u0441\u043e\u0432\u044b\u0439 \u0441\u0435\u043a\u0442\u043e\u0440 \u2014 13339 \u0432\u0430\u043a.<br>\u0423\u0441\u043b\u0443\u0433\u0438 \u0434\u043b\u044f \u0431\u0438\u0437\u043d\u0435\u0441\u0430 \u2014 6040 \u0432\u0430\u043a.<br>\u0420\u043e\u0437\u043d\u0438\u0447\u043d\u0430\u044f \u0442\u043e\u0440\u0433\u043e\u0432\u043b\u044f \u2014 5653 \u0432\u0430\u043a.<br>\u0422\u0435\u043b\u0435\u043a\u043e\u043c\u043c\u0443\u043d\u0438\u043a\u0430\u0446\u0438\u0438, \u0441\u0432\u044f\u0437\u044c \u2014 5187 \u0432\u0430\u043a.",
      "\u0422\u043e\u043f 5 \u043e\u0442\u0440\u0430\u0441\u043b\u0435\u0439:<br>\u0418\u043d\u0444\u043e\u0440\u043c\u0430\u0446\u0438\u043e\u043d\u043d\u044b\u0435 \u0442\u0435\u0445\u043d\u043e\u043b\u043e\u0433\u0438\u0438, \u0441\u0438\u0441\u0442\u0435\u043c\u043d\u0430\u044f \u0438\u043d\u0442\u0435\u0433\u0440\u0430\u0446\u0438\u044f, \u0438\u043d\u0442\u0435\u0440\u043d\u0435\u0442 \u2014 1275 \u0432\u0430\u043a.<br>\u0424\u0438\u043d\u0430\u043d\u0441\u043e\u0432\u044b\u0439 \u0441\u0435\u043a\u0442\u043e\u0440 \u2014 439 \u0432\u0430\u043a.<br>\u0423\u0441\u043b\u0443\u0433\u0438 \u0434\u043b\u044f \u0431\u0438\u0437\u043d\u0435\u0441\u0430 \u2014 341 \u0432\u0430\u043a.<br>\u0420\u043e\u0437\u043d\u0438\u0447\u043d\u0430\u044f \u0442\u043e\u0440\u0433\u043e\u0432\u043b\u044f \u2014 262 \u0432\u0430\u043a.<br>\u0413\u043e\u0441\u0442\u0438\u043d\u0438\u0446\u044b, \u0440\u0435\u0441\u0442\u043e\u0440\u0430\u043d\u044b, \u043e\u0431\u0449\u0435\u043f\u0438\u0442, \u043a\u0435\u0439\u0442\u0435\u0440\u0438\u043d\u0433 \u2014 161 \u0432\u0430\u043a.",
      "\u0422\u043e\u043f 5 \u043e\u0442\u0440\u0430\u0441\u043b\u0435\u0439:<br>\u0418\u043d\u0444\u043e\u0440\u043c\u0430\u0446\u0438\u043e\u043d\u043d\u044b\u0435 \u0442\u0435\u0445\u043d\u043e\u043b\u043e\u0433\u0438\u0438, \u0441\u0438\u0441\u0442\u0435\u043c\u043d\u0430\u044f \u0438\u043d\u0442\u0435\u0433\u0440\u0430\u0446\u0438\u044f, \u0438\u043d\u0442\u0435\u0440\u043d\u0435\u0442 \u2014 6811 \u0432\u0430\u043a.<br>\u0423\u0441\u043b\u0443\u0433\u0438 \u0434\u043b\u044f \u0431\u0438\u0437\u043d\u0435\u0441\u0430 \u2014 647 \u0432\u0430\u043a.<br>\u0424\u0438\u043d\u0430\u043d\u0441\u043e\u0432\u044b\u0439 \u0441\u0435\u043a\u0442\u043e\u0440 \u2014 489 \u0432\u0430\u043a.<br>\u0421\u041c\u0418, \u043c\u0430\u0440\u043a\u0435\u0442\u0438\u043d\u0433, \u0440\u0435\u043a\u043b\u0430\u043c\u0430, BTL, PR, \u0434\u0438\u0437\u0430\u0439\u043d, \u043f\u0440\u043e\u0434\u044e\u0441\u0438\u0440\u043e\u0432\u0430\u043d\u0438\u0435 \u2014 420 \u0432\u0430\u043a.<br>\u0420\u043e\u0437\u043d\u0438\u0447\u043d\u0430\u044f \u0442\u043e\u0440\u0433\u043e\u0432\u043b\u044f \u2014 340 \u0432\u0430\u043a.",
      "\u0422\u043e\u043f 5 \u043e\u0442\u0440\u0430\u0441\u043b\u0435\u0439:<br>\u0418\u043d\u0444\u043e\u0440\u043c\u0430\u0446\u0438\u043e\u043d\u043d\u044b\u0435 \u0442\u0435\u0445\u043d\u043e\u043b\u043e\u0433\u0438\u0438, \u0441\u0438\u0441\u0442\u0435\u043c\u043d\u0430\u044f \u0438\u043d\u0442\u0435\u0433\u0440\u0430\u0446\u0438\u044f, \u0438\u043d\u0442\u0435\u0440\u043d\u0435\u0442 \u2014 101607 \u0432\u0430\u043a.<br>\u0424\u0438\u043d\u0430\u043d\u0441\u043e\u0432\u044b\u0439 \u0441\u0435\u043a\u0442\u043e\u0440 \u2014 37458 \u0432\u0430\u043a.<br>\u0420\u043e\u0437\u043d\u0438\u0447\u043d\u0430\u044f \u0442\u043e\u0440\u0433\u043e\u0432\u043b\u044f \u2014 15504 \u0432\u0430\u043a.<br>\u0423\u0441\u043b\u0443\u0433\u0438 \u0434\u043b\u044f \u0431\u0438\u0437\u043d\u0435\u0441\u0430 \u2014 13288 \u0432\u0430\u043a.<br>\u0422\u0435\u043b\u0435\u043a\u043e\u043c\u043c\u0443\u043d\u0438\u043a\u0430\u0446\u0438\u0438, \u0441\u0432\u044f\u0437\u044c \u2014 8882 \u0432\u0430\u043a.",
      "\u0422\u043e\u043f 5 \u043e\u0442\u0440\u0430\u0441\u043b\u0435\u0439:<br>\u0418\u043d\u0444\u043e\u0440\u043c\u0430\u0446\u0438\u043e\u043d\u043d\u044b\u0435 \u0442\u0435\u0445\u043d\u043e\u043b\u043e\u0433\u0438\u0438, \u0441\u0438\u0441\u0442\u0435\u043c\u043d\u0430\u044f \u0438\u043d\u0442\u0435\u0433\u0440\u0430\u0446\u0438\u044f, \u0438\u043d\u0442\u0435\u0440\u043d\u0435\u0442 \u2014 9925 \u0432\u0430\u043a.<br>\u0424\u0438\u043d\u0430\u043d\u0441\u043e\u0432\u044b\u0439 \u0441\u0435\u043a\u0442\u043e\u0440 \u2014 1847 \u0432\u0430\u043a.<br>\u0423\u0441\u043b\u0443\u0433\u0438 \u0434\u043b\u044f \u0431\u0438\u0437\u043d\u0435\u0441\u0430 \u2014 938 \u0432\u0430\u043a.<br>\u0420\u043e\u0437\u043d\u0438\u0447\u043d\u0430\u044f \u0442\u043e\u0440\u0433\u043e\u0432\u043b\u044f \u2014 828 \u0432\u0430\u043a.<br>\u0421\u041c\u0418, \u043c\u0430\u0440\u043a\u0435\u0442\u0438\u043d\u0433, \u0440\u0435\u043a\u043b\u0430\u043c\u0430, BTL, PR, \u0434\u0438\u0437\u0430\u0439\u043d, \u043f\u0440\u043e\u0434\u044e\u0441\u0438\u0440\u043e\u0432\u0430\u043d\u0438\u0435 \u2014 657 \u0432\u0430\u043a.",
      "\u0422\u043e\u043f 5 \u043e\u0442\u0440\u0430\u0441\u043b\u0435\u0439:<br>\u0418\u043d\u0444\u043e\u0440\u043c\u0430\u0446\u0438\u043e\u043d\u043d\u044b\u0435 \u0442\u0435\u0445\u043d\u043e\u043b\u043e\u0433\u0438\u0438, \u0441\u0438\u0441\u0442\u0435\u043c\u043d\u0430\u044f \u0438\u043d\u0442\u0435\u0433\u0440\u0430\u0446\u0438\u044f, \u0438\u043d\u0442\u0435\u0440\u043d\u0435\u0442 \u2014 591 \u0432\u0430\u043a.<br>\u0424\u0438\u043d\u0430\u043d\u0441\u043e\u0432\u044b\u0439 \u0441\u0435\u043a\u0442\u043e\u0440 \u2014 296 \u0432\u0430\u043a.<br>\u0420\u043e\u0437\u043d\u0438\u0447\u043d\u0430\u044f \u0442\u043e\u0440\u0433\u043e\u0432\u043b\u044f \u2014 159 \u0432\u0430\u043a.<br>\u0423\u0441\u043b\u0443\u0433\u0438 \u0434\u043b\u044f \u0431\u0438\u0437\u043d\u0435\u0441\u0430 \u2014 121 \u0432\u0430\u043a.<br>\u042d\u043b\u0435\u043a\u0442\u0440\u043e\u043d\u0438\u043a\u0430, \u043f\u0440\u0438\u0431\u043e\u0440\u043e\u0441\u0442\u0440\u043e\u0435\u043d\u0438\u0435, \u0431\u044b\u0442\u043e\u0432\u0430\u044f \u0442\u0435\u0445\u043d\u0438\u043a\u0430, \u043a\u043e\u043c\u043f\u044c\u044e\u0442\u0435\u0440\u044b \u0438 \u043e\u0440\u0433\u0442\u0435\u0445\u043d\u0438\u043a\u0430 \u2014 113 \u0432\u0430\u043a.",
      "\u0422\u043e\u043f 5 \u043e\u0442\u0440\u0430\u0441\u043b\u0435\u0439:<br>\u0418\u043d\u0444\u043e\u0440\u043c\u0430\u0446\u0438\u043e\u043d\u043d\u044b\u0435 \u0442\u0435\u0445\u043d\u043e\u043b\u043e\u0433\u0438\u0438, \u0441\u0438\u0441\u0442\u0435\u043c\u043d\u0430\u044f \u0438\u043d\u0442\u0435\u0433\u0440\u0430\u0446\u0438\u044f, \u0438\u043d\u0442\u0435\u0440\u043d\u0435\u0442 \u2014 287 \u0432\u0430\u043a.<br>\u0423\u0441\u043b\u0443\u0433\u0438 \u0434\u043b\u044f \u0431\u0438\u0437\u043d\u0435\u0441\u0430 \u2014 29 \u0432\u0430\u043a.<br>\u0421\u041c\u0418, \u043c\u0430\u0440\u043a\u0435\u0442\u0438\u043d\u0433, \u0440\u0435\u043a\u043b\u0430\u043c\u0430, BTL, PR, \u0434\u0438\u0437\u0430\u0439\u043d, \u043f\u0440\u043e\u0434\u044e\u0441\u0438\u0440\u043e\u0432\u0430\u043d\u0438\u0435 \u2014 19 \u0432\u0430\u043a.<br>\u041e\u0431\u0440\u0430\u0437\u043e\u0432\u0430\u0442\u0435\u043b\u044c\u043d\u044b\u0435 \u0443\u0447\u0440\u0435\u0436\u0434\u0435\u043d\u0438\u044f \u2014 19 \u0432\u0430\u043a.<br>\u0424\u0438\u043d\u0430\u043d\u0441\u043e\u0432\u044b\u0439 \u0441\u0435\u043a\u0442\u043e\u0440 \u2014 11 \u0432\u0430\u043a.",
      "\u0422\u043e\u043f 5 \u044f\u0437\u044b\u043a\u043e\u0432:<br>SQL \u2014 5997 \u0432\u0430\u043a.<br>Python \u2014 2729 \u0432\u0430\u043a.<br>JavaScript/TypeScript \u2014 2251 \u0432\u0430\u043a.<br>Java \u2014 1288 \u0432\u0430\u043a.<br>C# \u2014 1276 \u0432\u0430\u043a.",
      "\u0422\u043e\u043f 5 \u044f\u0437\u044b\u043a\u043e\u0432:<br>SQL \u2014 101607 \u0432\u0430\u043a.<br>JavaScript/TypeScript \u2014 90400 \u0432\u0430\u043a.<br>Python \u2014 60598 \u0432\u0430\u043a.<br>Java \u2014 56892 \u0432\u0430\u043a.<br>PHP \u2014 37136 \u0432\u0430\u043a.",
      "\u0422\u043e\u043f 5 \u044f\u0437\u044b\u043a\u043e\u0432:<br>SQL \u2014 4530 \u0432\u0430\u043a.<br>C++ \u2014 3558 \u0432\u0430\u043a.<br>Python \u2014 3407 \u0432\u0430\u043a.<br>JavaScript/TypeScript \u2014 3110 \u0432\u0430\u043a.<br>Java \u2014 1722 \u0432\u0430\u043a.",
      "\u0422\u043e\u043f 5 \u044f\u0437\u044b\u043a\u043e\u0432:<br>SQL \u2014 8882 \u0432\u0430\u043a.<br>Python \u2014 5187 \u0432\u0430\u043a.<br>JavaScript/TypeScript \u2014 3657 \u0432\u0430\u043a.<br>Java \u2014 3614 \u0432\u0430\u043a.<br>C++ \u2014 1699 \u0432\u0430\u043a.",
      "\u0422\u043e\u043f 5 \u044f\u0437\u044b\u043a\u043e\u0432:<br>JavaScript/TypeScript \u2014 8379 \u0432\u0430\u043a.<br>SQL \u2014 6273 \u0432\u0430\u043a.<br>PHP \u2014 6021 \u0432\u0430\u043a.<br>Python \u2014 4874 \u0432\u0430\u043a.<br>Go \u2014 2039 \u0432\u0430\u043a.",
      "\u0422\u043e\u043f 5 \u044f\u0437\u044b\u043a\u043e\u0432:<br>SQL \u2014 3181 \u0432\u0430\u043a.<br>JavaScript/TypeScript \u2014 2388 \u0432\u0430\u043a.<br>PHP \u2014 1413 \u0432\u0430\u043a.<br>C# \u2014 974 \u0432\u0430\u043a.<br>Python \u2014 952 \u0432\u0430\u043a.",
      "\u0422\u043e\u043f 5 \u044f\u0437\u044b\u043a\u043e\u0432:<br>SQL \u2014 2558 \u0432\u0430\u043a.<br>JavaScript/TypeScript \u2014 1542 \u0432\u0430\u043a.<br>PHP \u2014 1177 \u0432\u0430\u043a.<br>Python \u2014 836 \u0432\u0430\u043a.<br>Java \u2014 529 \u0432\u0430\u043a.",
      "\u0422\u043e\u043f 5 \u044f\u0437\u044b\u043a\u043e\u0432:<br>SQL \u2014 210 \u0432\u0430\u043a.<br>JavaScript/TypeScript \u2014 123 \u0432\u0430\u043a.<br>PHP \u2014 93 \u0432\u0430\u043a.<br>C# \u2014 45 \u0432\u0430\u043a.<br>Python \u2014 35 \u0432\u0430\u043a.",
      "\u0422\u043e\u043f 5 \u044f\u0437\u044b\u043a\u043e\u0432:<br>SQL \u2014 2361 \u0432\u0430\u043a.<br>Python \u2014 961 \u0432\u0430\u043a.<br>JavaScript/TypeScript \u2014 910 \u0432\u0430\u043a.<br>Java \u2014 741 \u0432\u0430\u043a.<br>C# \u2014 697 \u0432\u0430\u043a.",
      "\u0422\u043e\u043f 5 \u044f\u0437\u044b\u043a\u043e\u0432:<br>SQL \u2014 3531 \u0432\u0430\u043a.<br>JavaScript/TypeScript \u2014 765 \u0432\u0430\u043a.<br>Python \u2014 487 \u0432\u0430\u043a.<br>PHP \u2014 459 \u0432\u0430\u043a.<br>Java \u2014 381 \u0432\u0430\u043a.",
      "\u0422\u043e\u043f 5 \u044f\u0437\u044b\u043a\u043e\u0432:<br>SQL \u2014 1017 \u0432\u0430\u043a.<br>JavaScript/TypeScript \u2014 270 \u0432\u0430\u043a.<br>Python \u2014 218 \u0432\u0430\u043a.<br>C# \u2014 195 \u0432\u0430\u043a.<br>Java \u2014 169 \u0432\u0430\u043a.",
      "\u0422\u043e\u043f 5 \u044f\u0437\u044b\u043a\u043e\u0432:<br>SQL \u2014 871 \u0432\u0430\u043a.<br>C++ \u2014 804 \u0432\u0430\u043a.<br>Python \u2014 526 \u0432\u0430\u043a.<br>JavaScript/TypeScript \u2014 408 \u0432\u0430\u043a.<br>C# \u2014 324 \u0432\u0430\u043a.",
      "\u0422\u043e\u043f 5 \u044f\u0437\u044b\u043a\u043e\u0432:<br>SQL \u2014 996 \u0432\u0430\u043a.<br>JavaScript/TypeScript \u2014 402 \u0432\u0430\u043a.<br>Python \u2014 256 \u0432\u0430\u043a.<br>PHP \u2014 256 \u0432\u0430\u043a.<br>Java \u2014 157 \u0432\u0430\u043a.",
      "\u0422\u043e\u043f 5 \u044f\u0437\u044b\u043a\u043e\u0432:<br>SQL \u2014 1199 \u0432\u0430\u043a.<br>JavaScript/TypeScript \u2014 562 \u0432\u0430\u043a.<br>Python \u2014 529 \u0432\u0430\u043a.<br>PHP \u2014 364 \u0432\u0430\u043a.<br>Java \u2014 302 \u0432\u0430\u043a.",
      "\u0422\u043e\u043f 5 \u044f\u0437\u044b\u043a\u043e\u0432:<br>JavaScript/TypeScript \u2014 121 \u0432\u0430\u043a.<br>SQL \u2014 105 \u0432\u0430\u043a.<br>PHP \u2014 89 \u0432\u0430\u043a.<br>Python \u2014 80 \u0432\u0430\u043a.<br>C++ \u2014 44 \u0432\u0430\u043a.",
      "\u0422\u043e\u043f 5 \u044f\u0437\u044b\u043a\u043e\u0432:<br>SQL \u2014 3771 \u0432\u0430\u043a.<br>Python \u2014 3450 \u0432\u0430\u043a.<br>JavaScript/TypeScript \u2014 3355 \u0432\u0430\u043a.<br>PHP \u2014 2027 \u0432\u0430\u043a.<br>Java \u2014 1553 \u0432\u0430\u043a.",
      "\u0422\u043e\u043f 5 \u044f\u0437\u044b\u043a\u043e\u0432:<br>SQL \u2014 15504 \u0432\u0430\u043a.<br>JavaScript/TypeScript \u2014 7204 \u0432\u0430\u043a.<br>Python \u2014 5653 \u0432\u0430\u043a.<br>PHP \u2014 5068 \u0432\u0430\u043a.<br>Java \u2014 3206 \u0432\u0430\u043a.",
      "\u0422\u043e\u043f 5 \u044f\u0437\u044b\u043a\u043e\u0432:<br>SQL \u2014 4825 \u0432\u0430\u043a.<br>JavaScript/TypeScript \u2014 2622 \u0432\u0430\u043a.<br>PHP \u2014 2243 \u0432\u0430\u043a.<br>Python \u2014 984 \u0432\u0430\u043a.<br>Java \u2014 618 \u0432\u0430\u043a.",
      "\u0422\u043e\u043f 5 \u044f\u0437\u044b\u043a\u043e\u0432:<br>SQL \u2014 37458 \u0432\u0430\u043a.<br>Java \u2014 18728 \u0432\u0430\u043a.<br>Python \u2014 13339 \u0432\u0430\u043a.<br>JavaScript/TypeScript \u2014 11854 \u0432\u0430\u043a.<br>C# \u2014 4592 \u0432\u0430\u043a.",
      "\u0422\u043e\u043f 5 \u044f\u0437\u044b\u043a\u043e\u0432:<br>SQL \u2014 13288 \u0432\u0430\u043a.<br>JavaScript/TypeScript \u2014 9751 \u0432\u0430\u043a.<br>Python \u2014 6040 \u0432\u0430\u043a.<br>Java \u2014 5873 \u0432\u0430\u043a.<br>PHP \u2014 4546 \u0432\u0430\u043a.",
      "\u0422\u043e\u043f 5 \u044f\u0437\u044b\u043a\u043e\u0432:<br>SQL \u2014 2316 \u0432\u0430\u043a.<br>Python \u2014 1024 \u0432\u0430\u043a.<br>JavaScript/TypeScript \u2014 929 \u0432\u0430\u043a.<br>Java \u2014 744 \u0432\u0430\u043a.<br>C# \u2014 600 \u0432\u0430\u043a.",
      "\u0422\u043e\u043f 5 \u044f\u0437\u044b\u043a\u043e\u0432:<br>SQL \u2014 1417 \u0432\u0430\u043a.<br>JavaScript/TypeScript \u2014 533 \u0432\u0430\u043a.<br>C# \u2014 439 \u0432\u0430\u043a.<br>Python \u2014 368 \u0432\u0430\u043a.<br>Java \u2014 349 \u0432\u0430\u043a.",
      "\u0422\u043e\u043f 5 \u044f\u0437\u044b\u043a\u043e\u0432:<br>SQL \u2014 2818 \u0432\u0430\u043a.<br>JavaScript/TypeScript \u2014 1036 \u0432\u0430\u043a.<br>C# \u2014 834 \u0432\u0430\u043a.<br>Python \u2014 832 \u0432\u0430\u043a.<br>Java \u2014 763 \u0432\u0430\u043a.",
      "\u0422\u043e\u043f 5 \u044f\u0437\u044b\u043a\u043e\u0432:<br>SQL \u2014 3317 \u0432\u0430\u043a.<br>JavaScript/TypeScript \u2014 1361 \u0432\u0430\u043a.<br>Python \u2014 913 \u0432\u0430\u043a.<br>PHP \u2014 835 \u0432\u0430\u043a.<br>C# \u2014 437 \u0432\u0430\u043a.",
      "\u0422\u043e\u043f 5 \u044f\u0437\u044b\u043a\u043e\u0432:<br>SQL \u2014 3073 \u0432\u0430\u043a.<br>Python \u2014 1872 \u0432\u0430\u043a.<br>JavaScript/TypeScript \u2014 1574 \u0432\u0430\u043a.<br>Go \u2014 1097 \u0432\u0430\u043a.<br>PHP \u2014 927 \u0432\u0430\u043a.",
      "\u0422\u043e\u043f 5 \u044f\u0437\u044b\u043a\u043e\u0432:<br>SQL \u2014 1082 \u0432\u0430\u043a.<br>JavaScript/TypeScript \u2014 741 \u0432\u0430\u043a.<br>PHP \u2014 463 \u0432\u0430\u043a.<br>Java \u2014 247 \u0432\u0430\u043a.<br>Python \u2014 230 \u0432\u0430\u043a.",
      "\u0422\u043e\u043f 5 \u044f\u0437\u044b\u043a\u043e\u0432:<br>SQL \u2014 415 \u0432\u0430\u043a.<br>JavaScript/TypeScript \u2014 249 \u0432\u0430\u043a.<br>PHP \u2014 150 \u0432\u0430\u043a.<br>Java \u2014 139 \u0432\u0430\u043a.<br>Python \u2014 73 \u0432\u0430\u043a.",
      "\u0422\u043e\u043f 5 \u044f\u0437\u044b\u043a\u043e\u0432:<br>JavaScript/TypeScript \u2014 92 \u0432\u0430\u043a.<br>SQL \u2014 74 \u0432\u0430\u043a.<br>PHP \u2014 65 \u0432\u0430\u043a.<br>Python \u2014 65 \u0432\u0430\u043a.<br>Java \u2014 32 \u0432\u0430\u043a.",
      "\u0422\u043e\u043f 5 \u044f\u0437\u044b\u043a\u043e\u0432:<br>SQL \u2014 2075 \u0432\u0430\u043a.<br>JavaScript/TypeScript \u2014 1018 \u0432\u0430\u043a.<br>C# \u2014 780 \u0432\u0430\u043a.<br>C++ \u2014 669 \u0432\u0430\u043a.<br>PHP \u2014 617 \u0432\u0430\u043a.",
      "\u0422\u043e\u043f 5 \u044f\u0437\u044b\u043a\u043e\u0432:<br>SQL \u2014 479 \u0432\u0430\u043a.<br>JavaScript/TypeScript \u2014 244 \u0432\u0430\u043a.<br>Python \u2014 188 \u0432\u0430\u043a.<br>Java \u2014 131 \u0432\u0430\u043a.<br>Kotlin \u2014 89 \u0432\u0430\u043a.",
    ],
    hovertemplate:
      "<b>\u0423\u0437\u0435\u043b %{label}</b><br>%{customdata}<extra></extra>",
  },
  link: {
    source: [
      0, 1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 11, 12, 13, 14, 15, 16, 17, 18, 19, 0,
      1, 2, 3, 4, 5, 6, 20, 7, 8, 9, 10, 11, 12, 13, 14, 15, 16, 17, 18, 19, 0,
      1, 2, 3, 4, 5, 6, 20, 7, 8, 9, 10, 11, 12, 13, 14, 15, 16, 17, 18, 19, 0,
      1, 2, 3, 4, 5, 6, 20, 7, 8, 9, 10, 11, 12, 13, 14, 15, 16, 17, 18, 19, 0,
      1, 2, 3, 4, 5, 6, 20, 7, 8, 9, 10, 11, 12, 13, 14, 15, 16, 17, 18, 19, 0,
      1, 2, 3, 4, 5, 6, 20, 7, 8, 9, 10, 11, 12, 13, 14, 15, 16, 17, 18, 19, 0,
      1, 2, 3, 5, 6, 20, 7, 8, 9, 10, 11, 12, 13, 14, 15, 16, 17, 18, 19, 1, 2,
      3, 6, 7, 8, 9, 12, 13, 14, 17, 18, 19, 0, 1, 2, 3, 4, 5, 6, 7, 8, 9, 10,
      11, 12, 13, 14, 15, 16, 17, 18, 19, 1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 11, 12,
      13, 14, 15, 16, 17, 18, 19, 1, 2, 3, 5, 6, 7, 8, 9, 10, 11, 12, 13, 14,
      15, 16, 17, 18, 19, 0, 1, 2, 3, 5, 6, 7, 8, 9, 10, 11, 12, 13, 14, 15, 16,
      17, 18, 19, 0, 1, 2, 3, 5, 6, 20, 7, 8, 9, 10, 11, 12, 13, 14, 15, 16, 17,
      18, 19, 0, 1, 2, 3, 5, 6, 7, 8, 9, 10, 11, 12, 13, 14, 15, 16, 17, 18, 19,
      1, 2, 3, 5, 6, 20, 7, 8, 9, 10, 12, 13, 14, 16, 17, 18, 0, 1, 2, 3, 4, 5,
      6, 20, 7, 8, 9, 10, 11, 12, 13, 14, 15, 16, 17, 18, 19, 0, 1, 2, 3, 4, 5,
      6, 20, 7, 8, 9, 10, 11, 12, 13, 14, 15, 16, 17, 18, 19, 0, 1, 2, 3, 4, 5,
      6, 7, 8, 9, 10, 11, 12, 13, 14, 15, 16, 17, 18, 19, 0, 1, 2, 3, 4, 5, 6,
      20, 7, 8, 9, 10, 11, 12, 13, 14, 15, 16, 17, 18, 19, 0, 1, 2, 3, 4, 5, 6,
      20, 7, 8, 9, 10, 11, 12, 13, 14, 15, 16, 17, 18, 19, 1, 2, 3, 5, 6, 7, 8,
      9, 10, 11, 12, 13, 14, 15, 16, 17, 18, 19, 0, 1, 2, 3, 5, 6, 7, 8, 9, 10,
      11, 12, 13, 14, 15, 16, 17, 18, 19, 0, 1, 2, 3, 5, 6, 7, 8, 9, 10, 11, 12,
      13, 14, 15, 16, 17, 18, 19, 1, 2, 3, 5, 6, 20, 7, 8, 9, 10, 11, 12, 13,
      14, 15, 16, 17, 18, 19, 1, 2, 3, 5, 6, 7, 8, 9, 10, 11, 12, 13, 14, 15,
      16, 17, 18, 19, 1, 2, 3, 5, 6, 20, 7, 8, 9, 10, 12, 13, 14, 15, 16, 17,
      18, 19, 1, 2, 3, 5, 6, 7, 8, 9, 10, 13, 14, 15, 16, 17, 18, 19, 1, 2, 3,
      5, 6, 7, 8, 9, 10, 13, 14, 17, 18, 0, 1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 11,
      12, 13, 14, 15, 16, 17, 18, 19, 0, 1, 2, 3, 5, 6, 20, 7, 8, 9, 10, 12, 13,
      14, 15, 16, 17, 18,
    ],
    target: [
      21, 21, 21, 21, 21, 21, 21, 21, 21, 21, 21, 21, 21, 21, 21, 21, 21, 21,
      21, 21, 22, 22, 22, 22, 22, 22, 22, 22, 22, 22, 22, 22, 22, 22, 22, 22,
      22, 22, 22, 22, 22, 23, 23, 23, 23, 23, 23, 23, 23, 23, 23, 23, 23, 23,
      23, 23, 23, 23, 23, 23, 23, 23, 24, 24, 24, 24, 24, 24, 24, 24, 24, 24,
      24, 24, 24, 24, 24, 24, 24, 24, 24, 24, 24, 25, 25, 25, 25, 25, 25, 25,
      25, 25, 25, 25, 25, 25, 25, 25, 25, 25, 25, 25, 25, 25, 26, 26, 26, 26,
      26, 26, 26, 26, 26, 26, 26, 26, 26, 26, 26, 26, 26, 26, 26, 26, 26, 27,
      27, 27, 27, 27, 27, 27, 27, 27, 27, 27, 27, 27, 27, 27, 27, 27, 27, 27,
      27, 28, 28, 28, 28, 28, 28, 28, 28, 28, 28, 28, 28, 28, 29, 29, 29, 29,
      29, 29, 29, 29, 29, 29, 29, 29, 29, 29, 29, 29, 29, 29, 29, 29, 30, 30,
      30, 30, 30, 30, 30, 30, 30, 30, 30, 30, 30, 30, 30, 30, 30, 30, 30, 31,
      31, 31, 31, 31, 31, 31, 31, 31, 31, 31, 31, 31, 31, 31, 31, 31, 31, 32,
      32, 32, 32, 32, 32, 32, 32, 32, 32, 32, 32, 32, 32, 32, 32, 32, 32, 32,
      33, 33, 33, 33, 33, 33, 33, 33, 33, 33, 33, 33, 33, 33, 33, 33, 33, 33,
      33, 33, 34, 34, 34, 34, 34, 34, 34, 34, 34, 34, 34, 34, 34, 34, 34, 34,
      34, 34, 34, 35, 35, 35, 35, 35, 35, 35, 35, 35, 35, 35, 35, 35, 35, 35,
      35, 36, 36, 36, 36, 36, 36, 36, 36, 36, 36, 36, 36, 36, 36, 36, 36, 36,
      36, 36, 36, 36, 37, 37, 37, 37, 37, 37, 37, 37, 37, 37, 37, 37, 37, 37,
      37, 37, 37, 37, 37, 37, 37, 38, 38, 38, 38, 38, 38, 38, 38, 38, 38, 38,
      38, 38, 38, 38, 38, 38, 38, 38, 38, 39, 39, 39, 39, 39, 39, 39, 39, 39,
      39, 39, 39, 39, 39, 39, 39, 39, 39, 39, 39, 39, 40, 40, 40, 40, 40, 40,
      40, 40, 40, 40, 40, 40, 40, 40, 40, 40, 40, 40, 40, 40, 40, 41, 41, 41,
      41, 41, 41, 41, 41, 41, 41, 41, 41, 41, 41, 41, 41, 41, 41, 42, 42, 42,
      42, 42, 42, 42, 42, 42, 42, 42, 42, 42, 42, 42, 42, 42, 42, 42, 43, 43,
      43, 43, 43, 43, 43, 43, 43, 43, 43, 43, 43, 43, 43, 43, 43, 43, 43, 44,
      44, 44, 44, 44, 44, 44, 44, 44, 44, 44, 44, 44, 44, 44, 44, 44, 44, 44,
      45, 45, 45, 45, 45, 45, 45, 45, 45, 45, 45, 45, 45, 45, 45, 45, 45, 45,
      46, 46, 46, 46, 46, 46, 46, 46, 46, 46, 46, 46, 46, 46, 46, 46, 46, 46,
      47, 47, 47, 47, 47, 47, 47, 47, 47, 47, 47, 47, 47, 47, 47, 47, 48, 48,
      48, 48, 48, 48, 48, 48, 48, 48, 48, 48, 48, 49, 49, 49, 49, 49, 49, 49,
      49, 49, 49, 49, 49, 49, 49, 49, 49, 49, 49, 49, 49, 50, 50, 50, 50, 50,
      50, 50, 50, 50, 50, 50, 50, 50, 50, 50, 50, 50, 50,
    ],
    value: [
      2, 108, 510, 1276, 1, 1095, 163, 1288, 2251, 273, 47, 11, 45, 1198, 2729,
      51, 168, 5997, 148, 42, 826, 4471, 20816, 28636, 547, 19760, 5110, 287,
      56892, 90400, 14833, 4527, 296, 3796, 37136, 60598, 1275, 6811, 101607,
      9925, 591, 186, 1226, 3558, 1653, 15, 543, 76, 4, 1722, 3110, 340, 77, 40,
      232, 1452, 3407, 73, 97, 4530, 193, 113, 94, 647, 1699, 1438, 17, 1069,
      159, 1, 3614, 3657, 533, 160, 9, 835, 1631, 5187, 102, 206, 8882, 385, 56,
      4, 171, 867, 1479, 6, 2039, 421, 19, 1961, 8379, 803, 336, 3, 255, 6021,
      4874, 100, 420, 6273, 657, 39, 13, 121, 426, 974, 4, 151, 105, 5, 570,
      2388, 104, 20, 56, 97, 1413, 952, 61, 92, 3181, 80, 64, 145, 86, 299, 513,
      239, 56, 3, 529, 1542, 136, 17, 8, 30, 1177, 836, 57, 36, 2558, 50, 22, 3,
      19, 45, 1, 10, 123, 4, 3, 93, 35, 210, 1, 1, 2, 16, 290, 697, 6, 41, 13,
      741, 910, 34, 23, 17, 39, 340, 961, 65, 22, 2361, 62, 62, 20, 108, 314, 6,
      91, 31, 381, 765, 79, 10, 6, 14, 459, 487, 44, 7, 3531, 51, 87, 14, 64,
      195, 23, 26, 169, 270, 65, 2, 2, 14, 128, 218, 6, 10, 1017, 7, 10, 21,
      162, 804, 324, 73, 13, 318, 408, 40, 1, 14, 39, 75, 526, 6, 15, 871, 1,
      35, 1, 7, 102, 124, 41, 32, 6, 157, 402, 22, 14, 4, 15, 256, 256, 21, 8,
      996, 19, 15, 1, 64, 191, 137, 66, 7, 302, 562, 45, 2, 5, 19, 364, 529, 20,
      30, 1199, 32, 7, 22, 44, 22, 21, 4, 1, 35, 121, 13, 3, 7, 89, 80, 5, 105,
      12, 20, 227, 1077, 883, 11, 477, 176, 19, 1553, 3355, 275, 68, 10, 63,
      2027, 3450, 81, 188, 3771, 190, 14, 17, 172, 755, 2054, 7, 2251, 346, 1,
      3206, 7204, 1213, 232, 33, 162, 5068, 5653, 262, 340, 15504, 828, 159, 9,
      22, 161, 538, 4, 268, 65, 618, 2622, 221, 32, 6, 57, 2243, 984, 64, 38,
      4825, 114, 49, 33, 294, 2510, 4592, 30, 2336, 591, 11, 18728, 11854, 3110,
      648, 50, 637, 3661, 13339, 439, 489, 37458, 1847, 296, 49, 520, 2069,
      3145, 54, 1673, 512, 29, 5873, 9751, 1398, 357, 36, 235, 4546, 6040, 341,
      647, 13288, 938, 121, 19, 264, 600, 41, 51, 744, 929, 82, 25, 21, 65, 229,
      1024, 59, 26, 2316, 112, 50, 5, 55, 240, 439, 47, 19, 349, 533, 63, 9, 2,
      23, 242, 368, 19, 17, 1417, 19, 29, 7, 35, 293, 834, 55, 35, 763, 1036,
      69, 14, 9, 22, 278, 832, 53, 14, 2818, 43, 49, 29, 123, 437, 145, 68, 6,
      435, 1361, 150, 19, 21, 48, 835, 913, 49, 24, 3317, 101, 45, 72, 266, 835,
      1097, 70, 567, 1574, 216, 40, 1, 20, 927, 1872, 34, 231, 3073, 146, 8, 6,
      28, 158, 59, 44, 1, 247, 741, 138, 18, 8, 463, 230, 161, 7, 1082, 116, 11,
      7, 30, 63, 18, 19, 139, 249, 15, 3, 150, 73, 2, 3, 415, 9, 11, 2, 30, 16,
      13, 4, 32, 92, 7, 2, 65, 65, 74, 3, 19, 224, 669, 780, 6, 118, 27, 479,
      1018, 93, 22, 54, 27, 617, 543, 44, 16, 2075, 29, 53, 2, 4, 32, 85, 33, 5,
      1, 131, 244, 89, 24, 2, 89, 188, 4, 3, 479, 80,
    ],
    color: "rgba(127, 127, 127, 0.1)",
  },
};

const sankeyData: IThread[] = [];

for (let i = 0; i < rawIndData.link.value.length; i++) {
  const thread = {
    path: `${rawIndData.node.label[rawIndData.link.source[i]]} -> ${
      rawIndData.node.label[rawIndData.link.target[i]]
    }`,
    source: rawIndData.node.label[rawIndData.link.source[i]],
    target: rawIndData.node.label[rawIndData.link.target[i]],
    value: rawIndData.link.value[i],
  };
  sankeyData.push(thread);
}

export default sankeyData;
